/* eslint-disable import/prefer-default-export */
import _ from "lodash";
import AppConfig from "../config/AppConfig";
import { isEmpty } from "../common/Common";
import moment from "moment";

export const setRulesData = (data = [], config = {}, previousConfig = {}) => {
  data.forEach((rule) => {
    config[rule.rule_type] = {};
    previousConfig[rule.rule_type] = {};
    const subConfig = {};
    rule.configurations.forEach((setting) => {
      subConfig[setting.setting_name] = setting.setting_value;
      previousConfig[rule.rule_type][setting.setting_name] =
        setting.setting_value;
      if (
        rule.rule_type === "org_group_accounts" &&
        !isEmpty(subConfig.group_accounts) &&
        _.isString(subConfig.group_accounts) &&
        subConfig.group_accounts === "[]"
      ) {
        subConfig.group_accounts = [];
        previousConfig[rule.rule_type].group_accounts = [];
      }
      if (
        rule.rule_type === "org_billing_rules" &&
        !isEmpty(subConfig.require_prefix_for_invoice) &&
        subConfig.group_accounts === "true"
      ) {
        if (isEmpty(subConfig.no_of_chars_for_invoice_no)) {
          subConfig.no_of_chars_for_invoice_no = "ALL";
          previousConfig[rule.rule_type].no_of_chars_for_invoice_no = "ALL";
        }
        if(isEmpty(subConfig.round_off_decimals)){
          subConfig.round_off_decimals = AppConfig.default_decimal_points;
          previousConfig[rule.rule_type].round_off_decimals =  AppConfig.default_decimal_points;
        }
      }
      if (
        rule.rule_type === "org_billing_rules"
      ) {
        if(isEmpty(subConfig.start_driver_settlements_on)){
          subConfig.start_driver_settlements_on = 'BILLING_APPROVAL';
          previousConfig[rule.rule_type].start_driver_settlements_on =  'BILLING_APPROVAL';
        }
        if(isEmpty(subConfig.round_off_decimals)){
          subConfig.round_off_decimals = AppConfig.default_decimal_points;
          previousConfig[rule.rule_type].round_off_decimals =  AppConfig.default_decimal_points;
        }
        if(isEmpty(subConfig.move_unsatisfied_orders_to_unassigned)){
          subConfig.move_unsatisfied_orders_to_unassigned = "false";
          previousConfig[rule.rule_type].move_unsatisfied_orders_to_unassigned =  false;
          subConfig.unsatisfied_orders_to_unassigned_apply_for = "";
          previousConfig[rule.rule_type].unsatisfied_orders_to_unassigned_apply_for =  "";
        }else {
          if(
          subConfig.move_unsatisfied_orders_to_unassigned === 'true' && 
          isEmpty(subConfig.unsatisfied_orders_to_unassigned_apply_for)){
            subConfig.unsatisfied_orders_to_unassigned_apply_for = "AUTO_PREPLAN";
            previousConfig[rule.rule_type].unsatisfied_orders_to_unassigned_apply_for =  "AUTO_PREPLAN";
          }
        }
        if(isEmpty(subConfig.invoice_header_address)){
          subConfig.invoice_header_address = "ORG";
          previousConfig[rule.rule_type].invoice_header_address =  "ORG";
        }
        if(isEmpty(subConfig.require_driver_effort_insertion)){
          subConfig.require_driver_effort_insertion = "true";
          previousConfig[rule.rule_type].require_driver_effort_insertion =  "true";
        }
        if (isEmpty(subConfig.send_invoice_to_driver_after_invoice_approval)) {
          subConfig.send_invoice_to_driver_after_invoice_approval = "true";
          previousConfig[rule.rule_type].send_invoice_to_driver_after_invoice_approval = "true";
        }
        if (isEmpty(subConfig.invoice_number_length_type)) {
          subConfig.invoice_number_length_type = "with_account_code";
          previousConfig[rule.rule_type].invoice_number_length_type = "with_account_code";
        }
        if (isEmpty(subConfig.invoice_no_start_type)) {
          subConfig.invoice_no_start_type = "start_from_digit";
          previousConfig[rule.rule_type].invoice_no_start_type = "start_from_digit";
        }
        if (isEmpty(subConfig.create_manual_billing)) {
          subConfig.create_manual_billing = "true";
          previousConfig[rule.rule_type].create_manual_billing = "true";
        }
        if (isEmpty(subConfig.ask_to_provide_invoice_no_upon_billing_approval)) {
          subConfig.ask_to_provide_invoice_no_upon_billing_approval = "true";
          previousConfig[rule.rule_type].ask_to_provide_invoice_no_upon_billing_approval = "true";
        }
        if (isEmpty(subConfig.consider_driver_rates_from_billing)) {
          subConfig.consider_driver_rates_from_billing = "false";
          previousConfig[rule.rule_type].consider_driver_rates_from_billing = "false";
        }
        if (isEmpty(subConfig.allow_duplicate_zipcodes)) {
          subConfig.allow_duplicate_zipcodes = "false";
          previousConfig[rule.rule_type].allow_duplicate_zipcodes = "false";
        }
        if (isEmpty(subConfig.customize_transportation_invoice_line)) {
          subConfig.customize_transportation_invoice_line = "false";
          previousConfig[rule.rule_type].customize_transportation_invoice_line = "false";
        }
        if (isEmpty(subConfig.transportation_invoice_line_label)) {
          subConfig.transportation_invoice_line_label = "los_name";
          previousConfig[rule.rule_type].transportation_invoice_line_label = "los_name";
        }
        if (subConfig.transportation_invoice_line_label === "custom") {
          if (isEmpty(subConfig.pickup_line_name)) {
            subConfig.pickup_line_name = "Transportation Charges";
            previousConfig[rule.rule_type].pickup_line_name = "Transportation Charges";
          }
          if (isEmpty(subConfig.delivery_line_name)) {
            subConfig.delivery_line_name = "Transportation Charges";
            previousConfig[rule.rule_type].delivery_line_name = "Transportation Charges";
          }
          if (isEmpty(subConfig.linehaul_line_name)) {
            subConfig.linehaul_line_name = "Transportation Charges";
            previousConfig[rule.rule_type].linehaul_line_name = "Transportation Charges";
          }
        }
      }
    });
    previousConfig[rule.rule_type] = subConfig;
    config[rule.rule_type] = subConfig;
  });
  return { updatedConfig: config, backupConfig: previousConfig };
};


export const getHighestStartDateRecord = (contracts) => {
  const rec = contracts.reduce((prev, current) => {
    const prevStartDate = moment(prev.effective_start_date);
    const currentStartDate = moment(current.effective_start_date);
    return prevStartDate.isBefore(currentStartDate) ? current : prev;
  });
  return rec;
}