/* eslint-disable no-nested-ternary */
/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment, useContext } from 'react'
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types'
import _, { orderBy } from 'lodash'
import {
  Spin,
  Icon,
  Skeleton,
  Tabs,
  Collapse,
  Button,
  Row,
  Col,
  message,
} from '../../common/UIComponents'
import {
  isEmpty,
  alertMessage,
  randomNumber,
} from '../../common/Common'
import I18n from '../../common/I18n';
import { fetchAccountConfigs } from '../../api/Account'
import { fetchOrderDetails,fetchRateConfirmation, printOrderDetails } from '../../api/OrdersApi'
import AppConfig from '../../config/AppConfig'
import pickup_logo from '../../../assets/images/truck_pickup.svg'
import delivery_logo from '../../../assets/images/truck_delivery.svg'
import OrderDetails from './OrderDetails'
import BaseModal from '../BaseModal'
import SurveyResponse from '../surveys/SurveyResponse'
import { base64ToPDF, checkServiceExistance, renderAlertMessage } from '../../helpers/common'
import { locationDetails } from '../../helpers/orders'
import PendingOrdersActions from '../billing_screen/PendingOrdersActions'
import PrintLabels from "../orders/PrintLabels";
import CloneOrders from '../orders/CloneOrders'
import DispatchButton from '../orders/DispatchButton'
import { WarehouseContext } from '../../context/WarehouseContext';
import { OrgContext } from '../../context/OrgContext';
import EditIcon from '../common/EditIcon'
import Copyable from '../common/Copyable'
import Dms from '../../containers/Dms';
import { UserContext } from '../../context/UserContext'
import { OrderConfigContext } from '../../context/OrderConfigContext';
import SendStatusReport, { SendStatusReportComponent } from './SendStatusReport';

const { TabPane } = Tabs
const { Panel } = Collapse
class DetailsConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeKey: `details-${this.props.order_id}`,
      setNotesKey: 'shipper_note',
      order_id: this.props.order_id,
      preferences: [
        {
          s_no: 1,
          item_preference: '',
          item_option: [],
        },
        {
          s_no: 2,
          item_preference: '',
          item_option: [],
        },
        {
          s_no: 3,
          item_preference: '',
          item_option: [],
        },
      ],
      showQuestionForm: false,
      config: {},
      inProgress: false,
      uploadKey: '',
      imagesVisible: false,
      orders: {},
      currentProcessingOrder: '',
      showForm: false,
      showSurvey: false,
      surveyResponseId: '',
      surveyLocationId: null,
      currentProcessOrders: [],
      showBarCodeModal: false,
      configLoadng: false,
    }
  }

  componentDidMount() {
    this.getOrderDetails()
  }

  enableLoading = (loadingStatus) => {
    this.setState({
      inProgress: false,
    })
  }

  getTenderRateConfirmation = () => {
    const order_id = this.props.order_id
    this.setState({ inProgress: true})
    fetchRateConfirmation(order_id).then((result) => {
      if (result.success) {
        alertMessage(result.data.message, "success", 5);
        this.setState({ inProgress: false})
        this.getOrderDetails();
      } else {
        renderAlertMessage(result?.errors)
        this.setState({ inProgress: false})
      }
    }
    )}

   downloadOrderDetails = () => {
    message.loading({ 
      key: "download",
      content: "Downloading PDF...",
    });
    const {currentProcessOrders} = this.state;
    let currentProcessedOrders = currentProcessOrders.filter(order => !['PENDING', 'REJECTED'].includes(order.status))
    const customerOrderIds = currentProcessedOrders.map(order => order.id);
    let customerOrderHawbs = currentProcessedOrders.map(order => order.hawb);
    if (customerOrderHawbs.filter(e => e).length <= 0) {
      customerOrderHawbs = currentProcessedOrders.map(order => order.order_number);
    }
    const customer_order_id = customerOrderIds
     printOrderDetails(customer_order_id).then((result) => {
      if (result.success) {
        message.destroy();
        const firstOrder = currentProcessOrders[0];
        const filename = firstOrder.hawb ? firstOrder.hawb : firstOrder.order_number;
        base64ToPDF(result.orders.order_pdf, `${filename}.pdf`);
        alertMessage("PDF Downloaded Successfully" , "success", 10);
      } else {
        message.destroy();
        renderAlertMessage(result.errors)
      }
    });
  };

   getTenderRateConfirmation = () => {
    const order_id = this.props.order_id
    this.setState({ inProgress: true})
    fetchRateConfirmation(order_id).then((result) => {
      if (result.success) {
        alertMessage(result.data.message, "success", 5);
        this.setState({ inProgress: false})
        this.getOrderDetails();
      } else {
        renderAlertMessage(result?.errors)
        this.setState({ inProgress: false})
      }
    }
    )}

  getOrderDetails = () => {
    this.setState({
      inProgress: true,
    })
    const { orderConfig } = this.props;
    fetchOrderDetails(this.props.order_id, 'DISPATCHER').then((result) => {
      if (result.success) {
        // const currentOrders = [...result.orders]
        const currentOrders = [...result.orders]
          const currentProcessOrders = currentOrders.map(order => ({
          id: order.id,
          order_number: order.customer_order_number,
          status: order.status,
          nav_route_id: order.nav_route_id,
          type_of_order: order.type_of_order,
          driver_details: order.driver_details,
          driver_id: order.driver_id,
          fleet_id: order.fleet_id,
          is_location_based: AppConfig.orderTypes[order.type_of_order] ? AppConfig.orderTypes[order.type_of_order].is_location_based : false,
          scheduled_start_datetime: order.scheduled_start_datetime,
          hawb: order.hawb,
          mawb: order.mawb,
          has_tender : order.has_tender
        }))
        const orders = {}
        let orderItems = []
        if (currentOrders.length > 0) {
          const orderType = !isEmpty(currentOrders[0].type_of_order)
              ? currentOrders[0].type_of_order
              : 'D'
          const dependancyOrders = currentOrders.filter(
              (order) => !isEmpty(order.related_order),
          )
          orders.type_of_order =
              ['TR', 'TD'].includes(orderType) || dependancyOrders.length > 0
                  ? 'M'
                  : orderType

          /* start Assigning default order type to D */
          currentOrders.forEach((order, index) => {
            if (isEmpty(order.type_of_order)) {
              currentOrders[index].type_of_order = 'D'
            }
          })
          /* end Assigning default order type to D */
          if (orders.type_of_order === 'M') {
            currentOrders.forEach((order, index) => {
              if (!isEmpty(order.related_order)) {
                if (order.type_of_order === 'R') {
                  currentOrders[index].type_of_order = 'TR'
                } else if (order.type_of_order === 'D') {
                  currentOrders[index].type_of_order = 'TD'
                }
              }
            })
          }
          orders.account_id = currentOrders[0].account_id
          orders.account_code = currentOrders[0].account_code
          orders.organization_id = currentOrders[0].organization_id
        }
        AppConfig.orderTypes[orders.type_of_order].types.forEach(
            (details, index) => {
              const currentOrder = _.find(currentOrders, {
                type_of_order: details.type_of_order,
              })
              if (!isEmpty(currentOrder)) {
                currentOrder.order_appointments = []
                currentOrder.order_activities =
                    currentOrder.order_activities &&
                    currentOrder.order_activities.length > 0
                        ? currentOrder.order_activities.reverse()
                        : []
                const currentItems = currentOrder.order_items || []
                currentOrder.order_items = [...currentItems]
                currentOrder.is_location_based = details.is_location_based;
                if(details.is_location_based){
                  let locations = [];
                  if(currentOrder.locations && currentOrder.locations.length > 0){
                    locations = locationDetails(currentOrder);
                  }
                  currentOrder.locations = locations
                }
                orders[details.key] = currentOrder
                orderItems = [].concat(orderItems, currentItems)

              }
            },
        )

        this.setState(
            {
              // result.orders.customer_order
              orders,
              inProgress: false,
              orderItems,
              uploadKey: randomNumber(8),
              currentProcessOrders,
            },
            () => {
              this.setInitialData()
              this.getAccountConfigs()
            },
        )
      } else {
        renderAlertMessage(result.errors)
        this.setState({
          inProgress: false,
          currentProcessOrders: [],
        })
      }
    })
  }

  getAccountConfigs = () => {
    const { config, orders } = this.state
    this.setState({configLoadng : true})
    fetchAccountConfigs(orders.account_id, orders.organization_id).then(
        (result) => {
          if (result.success) {
            const accountConfigurations = result.account_configurations || []
            accountConfigurations.forEach((setting) => {
              config[setting.setting_name] = setting.setting_value
            })
            if (
                !isEmpty(config.pod_pic_settings) &&
                _.isString(config.pod_pic_settings) &&
                config.pod_pic_settings === '[]'
            ) {
              config.pod_pic_settings = []
            }
            if (isEmpty(config.communication_preference)) {
              config.communication_preference = 'SMSES'
            }
            this.setState({
              config,
              configLoadng : false
            })
          } else {
            renderAlertMessage(result.errors)
            this.setState({configLoadng : false})
          }
        },
    )
  }

  setInitialData = () => {
    const typeOfOrder = this.state.orders.type_of_order
    const orders = this.state.orders ? this.state.orders : {}
    const { orderConfig = []} = this.props;
    AppConfig.orderTypes[typeOfOrder].types.forEach((details, index) => {
      const preferences = _.cloneDeep(this.state.preferences)
      const currentOrder = orders[details.key] ? orders[details.key] : {}
      const customerPreferences = currentOrder.appointments || []
      customerPreferences.forEach((preferenceObj, index) => {
        const preferenceIndex = _.findIndex(preferences, ['s_no', index + 1])
        if (preferenceIndex >= 0) {
          preferences[preferenceIndex].item_preference = preferenceObj.appt_date
          preferences[preferenceIndex].item_option = preferenceObj.slots || []
          preferences[preferenceIndex].confirmed =
              preferenceObj.confirmed || false
          preferences[preferenceIndex].start_time =
              preferenceObj.start_time || null
          preferences[preferenceIndex].end_time = preferenceObj.end_time || null
          // preferences[preferenceIndex].has_expedite = preferenceObj.has_expedite === true
          preferences[preferenceIndex].has_expedite =
              currentOrder.has_expedite === true
        }
      })
      if(!orders?.[details.key]){
        orders[details.key] = {}
      }
      if(!orders[details.key]?.preferences){
        orders[details.key].preferences = []
      }
      orders[details.key].preferences = preferences.filter(
          (preference) =>
              preference.item_preference || preference.item_option.length > 0,
      )
    })
    this.setState({
      orders,
    })
  }

  goToTab = (step) => {
    this.setState({
      activeKey: step,
    })
  }

  handleSurveyDetails = (id, locationId = null) => {
    this.setState({
      showSurvey: true,
      surveyResponseId: id,
      surveyLocationId: locationId,
    })
  }

  closeSurveyDetails = () => {
    this.setState({
      showSurvey: false,
      surveyResponseId: null,
      surveyLocationId: null,
    })
  }

  handleStatusResult = (result) => {
    if (result.success) {
      alertMessage(I18n.t('messages.processed'), 10)
      this.getOrderDetails()
    } else {
      if (result.errors && result.errors.length > 0) {
        alertMessage(result.errors.join(','))
      }
    }
  }

  handleBarCodePrint = (visible) => {
    this.setState({ showBarCodeModal: visible });
  };

  renderBarCodeModal = () => {
    const nonPendingOrders = this.state.currentProcessOrders.filter(order => ![ 'REJECTED' ].includes(order.status)).map(order => ({ ...order, customer_order_number: order.order_number }));
    return (
      <BaseModal
        title={ "Print Barcodes" }
        placement="right"
        closable
        onCancel={ () => this.setState({ showBarCodeModal: false }) }
        visible={ this.state.showBarCodeModal }
        width="70%"
        style={ { top: 35 } }
        maskClosable={false}
      >
        <div className="smallTable">
        <Dms selectedOrders={ nonPendingOrders } org_id={ this.state.orders.organization_id } handleBarCodePrint={ () => this.handleBarCodePrint(false) } />
        </div>
      </BaseModal>
    );
  }

  render() {
    const { orders, currentProcessOrders,config } = this.state
    const { currentRole } = this.props.userContext
    const isShipper =  currentRole === 'shipper'
    const typeOfOrder = orders.type_of_order ? orders.type_of_order : '';
    const nonPendingOrders = currentProcessOrders.filter(order => !['PENDING', 'REJECTED'].includes(order.status)).map(order => order.id);
    const isLocationBased = currentProcessOrders.length > 0 && currentProcessOrders[0].is_location_based ? currentProcessOrders[0].is_location_based : false;
    const ordersData = _.get(this.state, "orders", {});
    const dynamicKey = Object.keys(ordersData).find(key => key.endsWith('_params'));
    const parms = _.get(this.state,`orders.${dynamicKey}`,{});
    const isConsolidatedPresent = !_.isEmpty(parms.mawb) && config.consolidated_mawbs === 'true';
    const { orderConfig } = this.props;
    return (
      <Fragment>
         {this.props.closable && _.isEmpty(orders) && (
          <Col>
            <Icon
              type="close"
              className="cursorPointer"
              style={{ display: "flex", justifyContent: "right" }}
              onClick={this.props.onClose}
            />
          </Col>
        )}
        <Spin spinning={this.state.inProgress} delay={1000}>
          <div className="tabHover">
          {!_.isEmpty(orders) ? (
            <Tabs
              // defaultActiveKey={`details-${this.props.order_id}`}
              size="small"
              tabBarExtraContent={
                <Row gutter={ 4 } type="flex" justify="end">
                  { !isShipper && <Fragment>
                  <Col>
                    { false && currentOrder.status === 'PENDING' && (
                      <div className="alignCenter">
                        <PendingOrdersActions
                          handleStatusResult={ this.handleStatusResult }
                          enableLoading={ this.enableLoading }
                          orderIds={ [ currentOrder.id ] }
                        />
                      </div>
                    ) }
                  </Col>
                  {isConsolidatedPresent ? (
                          this.state.configLoadng ? (
                            <span style={{ display: "inline-block" }}>
                              <Skeleton
                                active
                                title={{ width: "130px" }}
                                paragraph={false}
                              />
                            </span>
                          ) : (
                            <Col>
                              <Button
                                type="primary"
                                onClick={() => {
                                  if (dynamicKey) {
                                    this.props.history.push("/orders/create", {
                                      account_code: parms.account_code,
                                      mawb: parms.mawb,
                                      hawb: parms.hawb,
                                      reference_1: parms.reference_1,
                                      reference_2: parms.reference_2,
                                      reference_1_type: parms.reference_1_type,
                                      reference_2_type: parms.reference_2_type,
                                      references: parms.references
                                        ? parms.references
                                        : null,
                                      losData:
                                        parms.locations &&
                                        !_.isEmpty(parms.locations)
                                          ? parms.locations
                                          : parms.los,
                                    });
                                    localStorage.setItem(
                                      "lastPath",
                                      this.props.location.pathname
                                    );
                                  } else {
                                    console.error(
                                      "Dynamic key not found matching the pattern."
                                    );
                                  }
                                }}
                              >
                                Create New Order From This MAWB
                              </Button>
                            </Col>
                          )
                        ) : null}
                  {
                  currentProcessOrders.length > 0 &&
                    ['NEW', 'RECEIVED', 'VERIFIED'].includes(currentProcessOrders[0].status) && typeOfOrder === 'LH' && (
                    <Col>
                    <DispatchButton
                      orders={currentProcessOrders[0]}
                      getOrderDetails={this.getOrderDetails}
                      setRefreshValue={this.props.setRefreshValue}
                    />
                    </Col>
                  )}

                  {
                    false && <Col>
                      <SendStatusReportComponent order={this.state.orders} order_id={this.state.order_id}/>
                    </Col>
                  }
                  {currentProcessOrders[0]?.has_tender && currentProcessOrders[0].status === "ASSIGNED" && (

                  <Col>
                    <Button
                     type="primary"
                     icon="check"
                     size='small' 
                     className={ "buttonCoolBlue marginRight10" }
                     onClick={ () => this.getTenderRateConfirmation() }>
                      Rate Confirmation
                    </Button>
                  </Col>
                )}
                  <Col>
                    <Button
                     type="primary"
                     icon="printer"
                     size='small' 
                     className="buttonMitesse" 
                     onClick={ () => this.downloadOrderDetails() }>
                      Print Order
                    </Button>
                  </Col>

                    { this.props.showCloneButton && checkServiceExistance("SCA") && !_.isEmpty(orders) && (<Col>
                      <CloneOrders
                        resetOrders={ () => { } }
                        orders={ [ ...nonPendingOrders ] }
                        filter={ 'NEW' }
                        size="small"
                        handleEditOrder={ this.props.editClick }
                        handleOrderclonedStatus={ this.props.handleOrderclonedStatus }
                        warehouseFilter={ this.props.warehouseFilter }
                        organizationSettings={ this.props.organizationSettings }
                        selectedOrders={currentProcessOrders || []}
                      /></Col>
                    )}
                    { nonPendingOrders.length > 0 && (<Col>
                      <PrintLabels
                        className={ "buttonCoolBlue marginRight10" }
                        buttonType="primary"
                        size="small"
                        label={ I18n.t("order.download_labels") }
                        isDisabled={ false }
                        handleSuccess={ () => { } }
                        orderIds={ nonPendingOrders }
                        buttonIcon={"download"}
                      />
                      </Col>
                    )}
                   {!_.isEmpty(orders) && <Col>
                    <Button type="primary" icon="printer" onClick={ () => this.handleBarCodePrint(true) } size='small'>
                      Print Barcodes
                    </Button>
                  </Col>
                  }
                  <Col>
                    { this.props.showEdit &&  !_.isEmpty(orders) && checkServiceExistance("SCA") && (
                      <Button className="cursorPointer textBold" onClick={ this.props.editClick }>
                        <EditIcon />Edit
                      </Button>
                    ) }
                  </Col>
                  </Fragment>
                  }
                  {this.props.closable && (<Col>
                      <Icon type="close" className="cursorPointer" onClick={ this.props.onClose } />
                  </Col>)}

                </Row>

              }
              onChange={this.goToTab}
              activeKey={this.state.activeKey}
            >
              {!isEmpty(typeOfOrder) &&
                AppConfig.orderTypes[typeOfOrder].types.map(
                  (details, index) => {
                    const currentOrder = this.state.orders[details.key]
                      ? this.state.orders[details.key]
                      : {}
                    return (
                      <TabPane
                        tab={
                          <span>
                            <Icon
                                component={() => (
                                    <img
                                        className="order_details_tab_img"
                                        src={
                                          AppConfig.pickOrderTypes.includes(
                                              details.type_of_order,
                                          )
                                              ? pickup_logo
                                              : delivery_logo
                                        }
                                    />
                                )}
                            />
                            {details.label}
                            <span className="fontSize16 textBold">
                              {currentOrder.customer_order_number
                                ? ` (${currentOrder.customer_order_number})`
                                : ''} <Copyable text={currentOrder.customer_order_number}/>
                            </span>
                          </span>
                        }
                        //key={`${details.key}-${!isEmpty(currentOrder) ? currentOrder.id : ''}`}
                        key={`details-${!isEmpty(currentOrder) ? currentOrder.id : ''}`}

                      >
                        {isEmpty(currentOrder) || isEmpty(currentOrder.id) ? (
                          <Skeleton active />
                        ) : (
                          <Fragment>
                            <OrderDetails
                              order_id={currentOrder.id}
                              order={currentOrder}
                              details={{ ...details }}
                              config={{ ...this.state.config }}
                              surveyDetails={this.handleSurveyDetails}
                              handleSearch={this.handleSearch}
                              getOrderDetails={this.getOrderDetails}
                              tab={this.props.tab}
                              updateParentState={this.props.updateParentState}
                              isShipper={isShipper}
                              fromIndividualScreen={this.props.fromIndividualScreen}
                              orderConfig={orderConfig}
                              isConsolidatedPresent={isConsolidatedPresent}
                            />
                          </Fragment>
                        )}
                      </TabPane>
                    )
                  },
                )}
            </Tabs>
            ):
            (
              <Skeleton active />
            )}
          </div>
           {this.state.showSurvey && (
                <BaseModal
                    title="Survey Details"
                    onCancel={() => this.closeSurveyDetails()}
                    className="surveyModal"
                    width="70%"
                    style={{ top: 40, overflowY: 'auto' }}
                >
                  <SurveyResponse surveyId={this.state.surveyResponseId} surveyLocationId={this.state.surveyLocationId}/>
                </BaseModal>
            )}
          { this.state.showBarCodeModal && this.renderBarCodeModal() }
        </Spin>
      </Fragment>
    )
  }
}
DetailsConfig.propTypes = {
  order_id: PropTypes.string.isRequired,
  reqFrom: PropTypes.string,
  updateParentState: PropTypes.func.isRequired,
  editClick: PropTypes.func,
  showCloneButton : PropTypes.bool,
  handleOrderclonedStatus : PropTypes.func,
  setRefreshValue: PropTypes.func,
  closable: PropTypes.bool,
  fromIndividualScreen: PropTypes.bool,
  tab: PropTypes.string
}
DetailsConfig.defaultProps = {
  reqFrom: '',
  editClick: () => {},
  showCloneButton : true,
  setRefreshValue: () => {},
  handleOrderclonedStatus : () => {},
  closable: true,
  fromIndividualScreen: false,
  tab: "",
  updateParentState: () => {},
}

// export default DetailsConfig
export const DetailsConfigComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const userContext= useContext(UserContext);
  const { orderConfig = {
    orderTypes: {}
  }} = useContext(OrderConfigContext);
  return (
    <DetailsConfig
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      userContext={userContext}
      orderConfig={orderConfig}
      {...props}
    />
  );
}
);

export default DetailsConfigComponent;