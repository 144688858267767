import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import AddressForm from '../components/common/AddressForm';
import { loadGoogleMaps } from '../utils/Utils';
import AppConfig from '../config/AppConfig';
import IncorrectOrdersMap from './IncorrectOrdersMap';
import { Button,Row, Col, Spin, Badge } from 'antd';
import { confirmationPopup } from '../helpers/common';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import _ from "lodash"
import { alertMessage } from '../common/Common';
import I18n from '../common/I18n';
import { resolveAddressApi } from '../api/OrdersApi';
import userStore from '../stores/UserStore';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { validAddressToResolve } from '../helpers/preplan';
import AddressSearch from '../stories/components/AddressSearch';

function ResolveAddressModal (props) {
  const { activeWrongAddressInfo , wrongOrderinfo, loadMaps } = props;
  const { id : order_id, customer_order_number, location_id  } = wrongOrderinfo;
  const [ isMapLoaded, setIsMapLoaded ] = useState(false);
  const [ wrongAddress, setWrongAddress ] = useState(null);
  const [ madeChanges, setMadeChanges ] = useState(false);
  const [ isAddressReset, setIsAddressReset ] = useState(true);
  const [isLoading, setIsLoading] = useState(false)
  const [resetCustomMarker, setResetCustomMarker] = useState(false);

  useEffect(() => {
    setWrongAddress(activeWrongAddressInfo);
      if(loadMaps !== false) {
        loadGoogleMaps(() => {
          setIsMapLoaded(true);
        });
    } else {
       setIsMapLoaded(true);
    }
    
  }, []);

  const onSelectSuggestion = (fetchedAddress) => {
    setWrongAddress(fetchedAddress);
    setIsAddressReset(false);
    setMadeChanges(true);
  };

  const onCancel = () => {
    if (madeChanges) {
      const confirmationInfo = {
        title: I18n.t('general.are_you_sure'),
        content: I18n.t('messages.unsaved_changes'),
        onConfirm: () => {
          props.closeModal();
        },
      };
      confirmationPopup(confirmationInfo);
    } else {
      props.closeModal();
    }
  };

  const clearFalseNegative = () => {
    const org_id = userStore.getStateValue("selectedOrg")
    setIsLoading(true)
    resolveAddressApi.clearFalseNegative(org_id, location_id).then(() => {
      props.closeModal(true, location_id);
      alertMessage(I18n.t('messages.address_corrected'));
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const memoziedValidAddressToResolve = useCallback(validAddressToResolve, []);
  const onResolveAddress = () => {
    if (!memoziedValidAddressToResolve(wrongAddress)) {
        alertMessage(I18n.t("messages.invalid_address") , 'error')
      return;
    }
    const newAddress = {...wrongAddress};
    delete newAddress.coordinates;
    const payload = {
      org_id: userStore.getStateValue("selectedOrg"),
      order_ids: [location_id],
      address: newAddress,
    } 
    setIsLoading(true)
    resolveAddressApi.fixWrongAddress(payload).then(() => {
      props.closeModal(true , payload);
      alertMessage(I18n.t('messages.address_corrected'));
    }
    ).catch(() => {
      alertMessage(I18n.t('messages.address_not_corrected'));
    }
    ).finally(() => { setIsLoading(false) })
  }



  const resetWrongAddress = () => {
    setWrongAddress(activeWrongAddressInfo);
    setIsAddressReset(true);
    setMadeChanges(false);
  };

  const geoCodeUpdateAddress = (updatedAddress) => {
    const addressToGeocode = `${updatedAddress.address_line1}, ${updatedAddress.city}, ${updatedAddress.state}, ${updatedAddress.country}`;
    geocodeByAddress(addressToGeocode)
      .then(results => getLatLng(results[ 0 ]))
      .then(latLng => setWrongAddress({
        ...updatedAddress,
        coordinates : [ latLng.lng, latLng.lat ],
      }))
      .catch(error =>
        alertMessage(I18n.t("messages.invalid_address") , 'error'));
  }

  const geoCodeUpdateAddressDebounced =  _.debounce(geoCodeUpdateAddress, 1000)

  const handleCustomMarkerSelection = (address) => {
    setWrongAddress(address);
    setMadeChanges(true);
  };

  const handleResetCustomMarker = () => {
    setResetCustomMarker(true);
  };

  if (!isMapLoaded) return null;
  return (
    <>
      <Row type='flex'  gutter={4} style={{
        alignItems: 'baseline',
      }}>
        <Col>
         <Text strong>{ I18n.t("general.customer_order_number") }</Text>
        </Col>
        <Col>
          <Text>{ customer_order_number }</Text>
        </Col>
      </Row>
      <Spin spinning={ isLoading }>
        <Row>
          <Col span={ 24 }>
            <AddressSearch onSelect={ (address) => {
              handleResetCustomMarker();
              onSelectSuggestion(address.l_address);
            } } />

            <AddressForm
              autoComplete={ true }
              isAddressReset={ isAddressReset }
              includePredefinedStops={ false }
              address={
                wrongAddress
              }
              onChange={ (element, value, obj) => {
                handleResetCustomMarker();
                if (element == '') {
                  onSelectSuggestion(obj);
                }else{
                  const updatedAddress = { ...wrongAddress, [element]: value };
                  setWrongAddress(updatedAddress)
                  geoCodeUpdateAddressDebounced(updatedAddress);
                }
              } }

              onConsigneeLocationChange={ () => { } }
              isAdmin
              disabled={ false }
            />
          </Col>
        </Row>
        <div className="resolveAddressMapContainer">
          <IncorrectOrdersMap
            loadingElement={ <Spin spinning={ true } /> }
            containerElement={ <div style={ { height: "100%", width: "100%" } } /> }
            mapElement={ <div style={ { height: "100%", width: "100%" } } /> }
            googleMapURL={ `https://maps.googleapis.com/maps/api/js?key=${AppConfig.mapKey}&libraries=geometry,drawing,places` }
            orderInfo={ wrongAddress }
            handleCustomMarkerSelection={ handleCustomMarkerSelection }
            resetCustomMarker={ resetCustomMarker }
            handleResetCustomMarker={ () => { setResetCustomMarker(false) } }
            // orderInfo={ [wrongAddress ]}
          />
        </div>
        <div className="resolveAddressButtons" style={ {
          display: 'flex',
          justifyContent: 'center',
          margin: '20px 0 0 0',
          gap: 10,
        } }>
          <Button icon='undo' type="secondary" onClick={ () => {
            resetWrongAddress();
          } }> {I18n.t("general.clear")} </Button>
          {/* <Badge count={ 5 }>
            <a href="#" className="head-example" />
          </Badge> */}
          {/* <Badge count={ 5 }> */}
            <Button icon='check-circle' style={ {
              backgroundColor: '#00a854',
              color: '#fff',
              marginRight: '10px',
            } } onClick={ () => {
              clearFalseNegative();
            } }> { I18n.t("general.marK_as_verified") } </Button>
          {/* </Badge> */}
          <Button icon='save' type="primary" onClick={ () => {
            onResolveAddress();
          } }> {I18n.t("general.update")} </Button>
          <Button icon='close' type="danger" onClick={ () => {
            onCancel();
          } }> {I18n.t("general.cancel")} </Button>
        </div>
      </Spin>
    </>

  );
}

export default ResolveAddressModal;


ResolveAddressModal.propTypes = {
  activeWrongAddressInfo: PropTypes.shape().isRequired,
  closeModal: PropTypes.func.isRequired,
  wrongOrderinfo: PropTypes.shape().isRequired,
};


ResolveAddressModal.defaultProps = {
  activeWrongAddressInfo: () => { },
  closeModal: () => { },
  wrongOrderinfo : {},
};