/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Row,
  Icon,
  Avatar,
  Link,
} from "../../common/UIComponents";
import AddressInfo from "./AddressInfo";
import { formateImageName, randomColor, doFormate, isEmpty } from "../../common/Common";
import Text from "antd/lib/typography/Text";
import truck_icon from "../../../assets/images/truck.svg";
import BranchesIcon from "./BranchesIcon";
import I18n from "../../common/I18n";
import EditIcon from "./EditIcon";
import { isEmptyForBoolean } from "../orders/helpers";

const Profile = (props) => {
  const {
    profile,
    address,
    displayType,
    withLables,  
    showImage,
    isMobileScreen = false,
    withOutIconLables,
    showAddress,
    showEmail = true,
    showDistance = false,
    isCustomer = false,
    isDriver = false,
    isShipper=false,
    inBilling = false,
    styleObj = null,
    verifymedium = false,
    hideMail = false,
    sideBySide = false,
    edit=false,
    handleEdit = () => {},
    showMobileNo= true,
    currentOrder
   } = props;
  //var mobileNumber = profile.mobile_number;//"2008, 2009",

  var mobileNumber = [];
  let contact1 = "";
  let contact2 = "";
  const comapnyName = profile.company_name;
  if (profile && profile.mobile_number) {
    mobileNumber = profile.mobile_number.split(",");
  if (mobileNumber && mobileNumber.length > 0) {
    contact1 = mobileNumber[0].trim();
    if (mobileNumber.length > 1 && !isEmpty(mobileNumber[1])) {
      contact2 = mobileNumber[1].trim();
    }
    }
  }
  const renderEditIcon = edit ? <EditIcon handleClick={handleEdit} /> : null
  return (
    profile &&
    (sideBySide ? (
      <div>
        <Row type="flex" gutter={[8, 8]}>
          {comapnyName && (
            <Col
              className="textCenter textCaptilize"
              style={{ fontWeight: "bold", marginBottom: "5px" }}
            >
              <Icon type="bank" /> &nbsp;&nbsp;
              {profile.company_name ? profile.company_name : "NA"}
            </Col>
          )}
          <Col
            className="textCenter textCaptilize"
            style={{ fontWeight: "bold", marginBottom: "5px" }}
          >
            <Icon type="user" />
            &nbsp;&nbsp;
            {profile.full_name ? profile.full_name : "NA"}
          </Col>
        </Row>
        <Row type="flex">
          {showMobileNo && <Col>
            <Icon type="mobile" /> &nbsp;&nbsp;
            {profile.mobile_number ? profile.mobile_number : "NA"}
          </Col>
          }
          <Col>
            <Icon type="mail" />
            &nbsp;&nbsp;
            {profile.email ? profile.email : "NA"}
          </Col>
        </Row>
      </div>
    ) : (
      <Row>
        <Col xs={24}>
          {isMobileScreen && (
            <Row>
              <Col xs={24}>
                {!isEmpty(profile.company_name) && (
                  <Col className="textBold fontSize16">
                    <Fragment>
                      <Icon type="bank" /> &nbsp;&nbsp;
                      {profile.company_name}
                    </Fragment>
                  </Col>
                )}
                {profile.full_name && (
                  <Col className="textBold textCaptilize">
                    <Fragment>
                      <Icon type="user" /> &nbsp;&nbsp;
                      {profile.full_name}
                    </Fragment>
                  </Col>
                )}
                {showMobileNo && profile.mobile_number && (
                  <Col>
                    <Fragment>
                      <Icon type="mobile" /> &nbsp;&nbsp;
                      {profile.mobile_number}
                    </Fragment>
                  </Col>
                )}
                {showEmail && profile.email && (
                  <Col>
                    <Fragment>
                      <Icon type="mail" /> &nbsp;&nbsp;
                      {profile.email}
                    </Fragment>
                  </Col>
                )}
                {address && Object.keys(address).length > 0 && (
                  <Row>
                    <Col xs={24}>
                      <AddressInfo address={address} showIcon />
                    </Col>
                  </Row>
                )}
              </Col>
              <Col xs={24} />
            </Row>
          )}
          {!isMobileScreen && !withLables && displayType === "lineByLine" && (
            <Row>
              {showImage && (
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div className="driver-profile alignCenter paddingTop10">
                    {profile.image && profile.image.url ? (
                      <Avatar src={`${profile.image.url}`} size={60} />
                    ) : (
                      <Avatar
                        size={60}
                        style={{ backgroundColor: randomColor() }}
                      >
                        {formateImageName(profile.full_name)}
                      </Avatar>
                    )}
                  </div>
                </Col>
              )}
              <Col xs={24} sm={24} md={24} lg={24} className="padding5 ">
                <Col>
                  <Col>
                    <div
                      className="textCenter textCaptilize"
                      style={{ fontWeight: "bold", marginBottom: "5px" }}
                    >
                      {profile.full_name ? profile.full_name : "NA"}
                    </div>
                  </Col>
                  {showMobileNo &&<Col>
                    <div>
                      <h4 className="textCenter">
                        {profile.mobile_number ? profile.mobile_number : "NA"}
                      </h4>
                    </div>
                  </Col>}
                  {showEmail && (
                    <Col>
                      <div>
                        <h4 className="textCenter">
                          {profile.email ? profile.email : "NA"}
                        </h4>
                      </div>
                    </Col>
                  )}
                </Col>
              </Col>
            </Row>
          )}
          {!isMobileScreen && !withLables && (
            <Row>
              {showImage && (
                <Col xs={7} sm={7} md={7} lg={7}>
                  <div className="driver-profile alignCenter paddingTop10">
                    {profile.image && profile.image.url ? (
                      <Avatar src={`${profile.image.url}`} size={60} />
                    ) : (
                      <Avatar
                        size={60}
                        style={{ backgroundColor: randomColor() }}
                      >
                        {formateImageName(profile.full_name)}
                      </Avatar>
                    )}
                  </div>
                </Col>
              )}
              <Col xs={showImage ? 17 : 24} className="padding5">
                <Col
                  xs={isCustomer ? 12 : 24}
                  style={
                    isCustomer
                      ? {
                          borderRight: "1px solid rgb(0 0 0 / 13%)",
                          height: "140px",
                        }
                      : ""
                  }
                >
                  <Col>
                    <div
                      style={
                        styleObj
                          ? { ...styleObj }
                          : { fontWeight: "bold", fontSize: "16px" }
                      }
                      className={
                        !isCustomer
                          ? "textCaptilize"
                          : "height48 marginRight8 textCaptilize"
                      }
                    >
                      {isDriver && profile.full_name ? (
                        <>
                          <span>{profile.full_name}</span>{" "}
                          {(isEmptyForBoolean(currentOrder?.has_tender)) && renderEditIcon}
                        </>
                      ) : isEmpty(profile.company_name) && profile.full_name ? (
                        <>
                          <span>{profile.full_name}</span>{" "}
                          {renderEditIcon}
                        </>
                      ) : (
                        <>
                          <span>{profile.company_name}</span>{" "}
                          {renderEditIcon}
                        </>
                      )}
                    </div>
                  </Col>
                  {isDriver && (
                    <Col className={inBilling ? "" : "marginTop5"}>
                      <Row>
                        <span>
                          <Icon type="number" />
                          &nbsp;&nbsp;
                        </span>
                        <span>
                          <span>{doFormate(profile.employee_code)}</span>
                        </span>
                      </Row>
                    </Col>
                  )}
                  {(isCustomer || inBilling) && !isEmpty(profile.company_name) && (
                    <Col className="marginTop5 textCaptilize">
                      <Row>
                        <span>
                          <Icon type="user" />
                          &nbsp;&nbsp;
                        </span>
                        <span>
                          <span>
                            {profile.full_name ? profile.full_name : "NA"}
                          </span>
                        </span>
                      </Row>
                    </Col>
                  )}
                  <Col className="marginTop5 ">                  
                    {showMobileNo && (
                      <Fragment>
                    {!isEmpty(contact1.trim())
                    ? (
                      <Row>
                        <span>
                          <Icon type="mobile" />
                          &nbsp;&nbsp;
                        </span>
                        <span>
                          <span>{contact1}</span>
                        </span>
                      </Row>
                    ) : null}

                    {showMobileNo && !isEmpty(contact2.trim())  ? (
                      <Row>
                        <span>
                          <Icon type="mobile" />
                          &nbsp;&nbsp;
                        </span>
                        <span>
                          <span>{contact2}</span>
                        </span>
                      </Row>
                    ) : null}

                    {showMobileNo && isEmpty(contact1.trim()) && isEmpty(contact2.trim())  ? (
                      <Row>
                        <span>
                          <Icon type="mobile" />
                          &nbsp;&nbsp;
                        </span>
                        <span>
                          <span>NA</span>
                        </span>
                      </Row>
                    ) : null}
                    </Fragment>)}
                  </Col>
                  {false && isDriver && (
                    <Col className={inBilling ? "" : "marginTop5"}>
                      <Row>
                        <span>
                          <Icon
                            component={() => (
                              <img
                                style={{ height: "20px", marginTop: -5 }}
                                src={truck_icon}
                              />
                            )}
                          />
                          &nbsp;&nbsp;
                        </span>
                        <span
                          className="textCaptilize"
                          style={{ fontWeight: "bold", fontSize: 16 }}
                        >
                          Large Truck
                        </span>
                      </Row>
                    </Col>
                  )}
                  {showEmail && verifymedium && (
                    <Col className="marginTop5">
                      {!hideMail && (
                        <div>
                          <Icon type="mail" /> &nbsp;&nbsp;
                          {profile.email ? profile.email : "NA"}
                        </div>
                      )}
                    </Col>
                  )}
                  {showEmail &&
                    !verifymedium &&
                    !isEmpty(profile.email) &&
                    profile.email !== "NA" && (
                      <Col className="marginTop5">
                        <div>
                          <Icon type="mail" /> &nbsp;&nbsp;
                          <Link
                            onClick={() =>
                              (window.location = `mailto:${profile.email}`)
                            }
                          >
                            <span className="anchor_cursor">
                              {profile.email}
                            </span>
                          </Link>
                        </div>
                      </Col>
                    )}
                </Col>
                {isCustomer && (
                  <Fragment>
                    <Col xs={12}>
                      <Row>
                        <Col>
                          <div style={{ marginLeft: "16px" }}>
                            {showAddress && address ? (
                              Object.keys(address).length > 0 && (
                                <Row>
                                  <Col sm={2} xs={2} md={2} lg={2}>
                                    <Icon
                                      type="environment"
                                      style={{
                                        fontSize: "30px",
                                        marginLeft: "-11px",
                                      }}
                                    />
                                  </Col>
                                  <Col sm={22} xs={22} md={22} lg={22}>
                                    <div
                                      className={
                                        !isCustomer
                                          ? ""
                                          : "fontSize16 paddingLeft5"
                                      }
                                    >
                                      <AddressInfo
                                        address={address}
                                        displayType="mixFormat"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              )
                            ) : (
                              <Row>
                                <Col sm={2} xs={2} md={2} lg={2}>
                                  <Icon
                                    type="environment"
                                    style={{
                                      fontSize: "30px",
                                      marginLeft: "-11px",
                                    }}
                                  />
                                </Col>
                                <Col sm={22} xs={22} md={22} lg={22}>
                                  <Text type="secondary" className="fontSize16">
                                    {I18n.t("errors.address_not_found")}
                                  </Text>
                                </Col>
                              </Row>
                            )}
                            &nbsp;
                            {showDistance && !isEmpty(profile.distance) && (
                              <Row>
                                <Col md={24}>
                                  <div className="textBold">
                                    <span>
                                      <BranchesIcon />
                                      &nbsp;
                                      {I18n.t("general.distance")}&nbsp;:&nbsp;
                                      <span>
                                        {profile.distance} {""}mi.
                                      </span>
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Fragment>
                )}
              </Col>
            </Row>
          )}
          {!isMobileScreen && withLables && (
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} className="padding5">
                <Row className="line-height24">
                  <Col sm={11} xs={11} md={11} lg={11} className="textBold">
                    {I18n.t("general.first_name")}
                  </Col>
                  <Col xs={1}>:</Col>
                  <Col
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    className="textCaptilize"
                  >
                    {profile.full_name ? profile.full_name : "NA"}
                  </Col>
                </Row>
                {showEmail && (
                  <Row className="line-height24">
                    <Col sm={11} xs={11} md={11} lg={11} className="textBold">
                      {I18n.t("general.email")}
                    </Col>
                    <Col xs={1}>:</Col>
                    <Col sm={12} xs={12} md={12} lg={12} className="wordWrap">
                      {profile.email ? profile.email : "NA"}
                    </Col>
                  </Row>
                )}

                {showMobileNo && <Row className="line-height24">
                  <Col sm={11} xs={11} md={11} lg={11} className="textBold">
                    {I18n.t("general.mobile_number")}
                  </Col>
                  <Col xs={1}>:</Col>
                  <Col sm={12} xs={12} md={12} lg={12} className="wordWrap">
                    {profile.mobile_number ? profile.mobile_number : "NA"}
                  </Col>
                </Row>}

                <Col>
                  <Col xs={24}>
                    <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
                      {profile.full_name ? profile.full_name : "NA"}
                    </div>
                  </Col>
                  {showMobileNo && <Col>
                    <div>
                      <Icon type="mobile" /> &nbsp;&nbsp;
                      {profile.mobile_number ? profile.mobile_number : "NA"}
                    </div>
                  </Col>
                  }
                  {showEmail && (
                    <Col>
                      <div>
                        <Icon type="mail" />
                        &nbsp;&nbsp;
                        {profile.email ? profile.email : "NA"}
                      </div>
                    </Col>
                  )}
                  <Col>
                    {showAddress && address && Object.keys(address).length > 0 && (
                      <Row>
                        <Col sm={2} xs={2} md={2} lg={2}>
                          <Icon type="environment" />
                        </Col>
                        <Col sm={22} xs={22} md={22} lg={22}>
                          <div className="alignLeft">
                            <AddressInfo address={address} />
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Col>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    ))
  );
};

export default Profile;

Profile.propTypes = {
  profile: PropTypes.shape().isRequired,
  address: PropTypes.shape(),
  displayType: PropTypes.string,
  withLables: PropTypes.bool,
  showImage: PropTypes.bool,
  isMobileScreen: PropTypes.bool,
  showAddress: PropTypes.bool,
  styleObj: PropTypes.shape(),
};

Profile.defaultProps = {
  address: {},
  displayType: "",
  withLables: true,
  showImage: true,
  isMobileScreen: false,
  showAddress: true,
  styleObj: null
};
