import React, { Fragment, useContext, useRef } from "react";
import moment from "moment";
import AppConfig from "../../config/AppConfig";
import {
  alertMessage,
  checkNegitive,
  ellipseText,
  isEmpty,
} from "../../common/Common";
import I18n from "../../common/I18n";
import {
  Popover,
  Link,
  Tooltip,
  Icon,
  Row,
  Col,
  Popconfirm,
  InputNumber,
  Input,
  Tag,
  Typography,
  Table,
} from "../../common/UIComponents";
// import { retrieveAddress, retrieveOrderValue } from "../../helpers/orders";
import { checkAccessExistance, checkServiceExistance, formatFullName } from "../../helpers/common";
import TypeOfOrder from "../orders/TypeOfOrder";
import ValidateLocationErr from "../orders/ValidateLocationErr";
import ConsigneeDetails from "../common/ConsigneeDetails";
import OrderFieldDisplay from "../orders/OrderFieldDisplay";
import { VehicleImageData } from "../../common/VehicleImageData";
import { retrieveAddress, retrieveOrderValue } from "../../helpers/orders";
import { AppointmentDisplay, ZonesDisplay, fetchLocAddress, getAddress, getAddressForPreplanCol, renderOrderNumType } from "../dispatches/helpers";
import { OrgContext } from "../../context/OrgContext";
import OrderAppointments from "../orders/OrderAppointments";
import EditIcon from "./EditIcon";
import SettingIcon from "./SettingIcon";
import DeleteIcon from "./DeleteIcon";
import userStore from "../../stores/UserStore";
import Copyable from "./Copyable";
import { returnStatement } from "@babel/types";
import OrderBillingDetails from "../billing/OrderBillingDetails";
import CopyableContent from "./CopyableContent";
import DisplayTime from "./DisplayTime";
import EditDriverDetails from "./EditDriverDetails";
import BranchesIcon from "./BranchesIcon";
import FormIcon from "./FormIcon";
import PreplanOrderAccessorial from "../orders/PreplanOrderAccessorial";
import PreplanOrderNotes from "../orders/PreplanOrderNotes";
import CustomerOrderDetails from "../orders/CustomerOrderDetails";
import OrderAttachments from "../orders/OrderAttachments";
import AddressInfo from "./AddressInfo";
import EstimateData from "../../common/EstimateData";
import ManualBolViewer from "../../common/ManualBolViewer";
const _ = require('lodash');
import manualBol from "../../../assets/images/manualColor.png"


function displayTransferAddress (order,key) {
  if(key === 'PREPLAN'){
    const locations = order?.locations;
    const pickupLocation = _.find(locations, { type_of_loc: 'PICKUP' });
    const deliveryLocation = _.find(locations, { type_of_loc: 'DELIVERY' });
  
    const formatAddress = (location) => {
      const address = location?.l_address;
      if (!address?.address_line1 && !address?.city && !address?.state && !address?.zipcode) {
        return 'NA';
      }
      return `${address?.address_line1 ?? ''}, ${address?.city ?? ''}, ${address?.state ?? ''} ${address?.zipcode ?? ''}`.trim();
    };
  
    if (pickupLocation && deliveryLocation) {
      const pickupAddress = formatAddress(pickupLocation);
      const deliveryAddress = formatAddress(deliveryLocation);
      return `${pickupAddress} -> ${deliveryAddress}`;
    } else {
      const location = locations?.[0];
      const address = formatAddress(location);
      return `${address}`;
    }
  }else {
    const origin  = _.get(order, 'origin', {});
    const destination = _.get(order, 'destination', {});

    const formatAddress = (location) => {
      if (!location?.address_line1 && !location?.city && !location?.state && !location?.zipcode) {
        return 'NA';
      }
      return `${location?.address_line1 ?? ''}, ${location?.city ?? ''}, ${location?.state ?? ''} ${location?.zipcode ?? ''}`.trim();
    };

    if (origin && destination) {
      const pickupAddress = formatAddress(origin);
      const deliveryAddress = formatAddress(destination);
      return `${pickupAddress} -> ${deliveryAddress}`;
    }else{
      const address = order?.cs_location;
      const formattedAddress = formatAddress(address);
      return `${formattedAddress}`;
    }
  }
}
const ellipsisStyle = {
  whiteSpace: 'nowrap', 
  overflow: 'hidden',   
  textOverflow: 'ellipsis',
};
const setAddress = (data, type) => {
  const pickUpdata = data?.pickup_location_details
    ? data.pickup_location_details.l_address
    : {}
  const pickUpAddress = (
    <Popover
      title={I18n.t('general.pickup_address')}
      content={<AddressInfo address={pickUpdata} />}
      overlayStyle={{ width: 200 }}
      // getPopupContainer={(triggerNode) => triggerNode.parentNode}
    >
      {pickUpdata && pickUpdata.city ? `${pickUpdata.city}` : 'NA'}
    </Popover>
  )
  const deliveryData = data?.delivery_location_details
    ? data.delivery_location_details.l_address
    : {}
  const dropAddress = (
    <Popover
      title={I18n.t('general.delivery_address')}
      content={<AddressInfo address={deliveryData} />}
      overlayStyle={{ width: 200 }}
      // getPopupContainer={(triggerNode) => triggerNode.parentNode}
    >
      {deliveryData && deliveryData.city ? `${deliveryData.city}` : 'NA'}
    </Popover>
  )
  if (type === 'origin') {
    return pickUpAddress
  }
  return dropAddress
}




const CustomInputNumberEditor = ({ value, onValueChange, rowIndex, colKey, onKeyDown, placeholder, decimalPoints }) => {
  const inputRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault(); // Prevent default Tab behavior
      
      // Move focus to the next input field
      const nextInput = inputRef.current.nextElementSibling;
      if (nextInput) {
        nextInput.focus();
      }
    } else {
      onKeyDown(e);
    }
  };

  return (
    <InputNumber
      ref={inputRef}
      value={value}
      onChange={onValueChange}
      onKeyDown={handleKeyDown}
      size="small"
      placeholder={placeholder}
      min={0}
      precision={decimalPoints}
       //   <InputNumber
  //     value={params.data.charge}
  //     size="small"
  //     className="fontSize12"
  //     // onChange={(e) =>
  //     //   onChange(params.rowIndex, 'charge', e)
  //     // }
  //     onChange={(e) => {
  //       params.setValue(e); // Update the value in the grid
  //       params.node.setDataValue(params.colDef.field, e); // Update the data
  //     }}
  //     placeholder={I18n.t('reports.chargable')}
  //     min={0}
  //     precision={decimalPoints}
  //     style={{ width: 100 }}
    />
  );
};

export default CustomInputNumberEditor;


function TrackLinkRenderer({ data }) {
  const organization_id = userStore.getStateValue("selectedOrg");
  const trackLink = `${window.location.origin}/drivers_tracking/track_driver?organization_id=${userStore.getStateValue(
    "selectedOrg"
  )}&location_code=${data.location_code}`;

  return (
    <Copyable
      text={trackLink}
      tooltipText={I18n.t("location.track_link")}
      color="#1890ff"
      showTooltip
    >
      <Icon type="copy" style={{ cursor: 'pointer' }} />
    </Copyable>
  );
}


export const checkboxColumn = {
  key:"checkbox",
  field: "0",
  width: 50,
  checkboxSelection: true,
  headerCheckboxSelection: true,
  filter: false,
  sortable: false,
  floatingFilter:false,
  resizable: false,
  pinned: "left",
  suppressMenu: true,
  suppressMovable: true,
  suppressNavigable: true,
  suppressResize: true,
  headerCheckboxSelectionFilteredOnly:true,
  showDisabledCheckboxes: true,
};

// Checkbox column
export const dvCheckboxColumn = {
  key:"checkbox",
  width: 50,
  checkboxSelection: (params) => {
    return params.node.data.status === "PENDING"
  },
  showDisabledCheckboxes: false,
  // cellRenderer: (params) => {
  //   if (params.node.data.status !== "PENDING") { // Renderiza um checkbox desabilitado nas linhas isEnabled=false
  //       return `<input type="checkbox" disabled />`;
  //   }
  // },
  headerCheckboxSelection: true,
  filter: false,
  sortable: false,
  resizable: false,
  pinned: "left",
  suppressMenu: true,
  suppressMovable: true,
  suppressNavigable: true,
  suppressResize: true,
  headerCheckboxSelectionFilteredOnly:true,
  showDisabledCheckboxes: true,
  floatingFilter: false,
};

// Reference 1 column
export const reference1Column = {
  headerName: 'Reference 1',
  field: 'reference_1',
  isVisible : true,
  cellRenderer: (params) => <OrderFieldDisplay data={ params.data } toDisplay="reference_1" charsToLimit={ null } />,
  width: 140,
};

// Reference 2 column
export const reference2Column = {
  headerName: 'Reference 2',
  field: 'reference_2',
  isVisible : true,
  cellRenderer: (params) => <OrderFieldDisplay data={ params.data } toDisplay="reference_2" charsToLimit={ null } />,
  width: 140,
};

const getReferenceColumn = (showTriggerEdit, handleEditOrder, refreshCallback, refName, showEditIcon) => {
  const reference1Column = {
    headerName: _.capitalize(_.upperCase(refName)),
    field: refName,
    isVisible: true,
    cellRenderer: (params) => <OrderFieldDisplay data={params.data} toDisplay={refName} showEditIcon={showEditIcon} showTriggerEdit={showTriggerEdit} editClick={() => handleEditOrder(params.data.id)} refreshCallback={refreshCallback} charsToLimit={null} showOrderDetailsByReference={true} />,
    width: 140,
  };
  return reference1Column;
};

const EndUserTitle = () => {
  const { endUserName } = useContext(OrgContext);
  return (
    <div>
      <span className="endUserTitle">{ endUserName }</span>
    </div>
  );
};


// turn into function 
const getCustomerNameColumn = () => {
  const { endUserName } = useContext(OrgContext);
  return {
    headerName: endUserName,
    field: 'consignee',
    isVisible : true,
    valueGetter: (params) => params.data.customer_first_name || '',
    sortable: true,
    cellRenderer: (params) => (
      <div className="ellipsis-text">
        <Popover title={ I18n.t('general.customer_details') } content={ <ConsigneeDetails record={ params.data } key={ `consigneeDetails${params.data.id}` } /> }>
          { isEmpty(params.data.customer_first_name) ? 'N/A' : `${params.data.customer_first_name} ${!isEmpty(params.data.customer_last_name) ? params.data.customer_last_name : ''}` }
        </Popover>
      </div>
    ),
    cellStyle: {
      textAlign: 'left',
    },
  };
}


const getCustomerNameColumnForPreplan = () => {
  const { endUserName } = useContext(OrgContext);
  return {
    headerName: endUserName,
    field: 'consignee',
    isVisible : true,
    valueGetter: (params) => params.data.customer_first_name || '',
    sortable: true,
    cellRenderer: (params) => {
      if (["T", "LH"].includes(params.data.type_of_order)) {
        const locationNames = params.data.locations.map((loc, index) => {
          const name = `${loc.first_name} ${!isEmpty(loc.last_name) ? loc.last_name : ""}`;
          return `${loc.type_of_loc === 'PICKUP' ? 'P: ' : 'D: '}${name}`;
        }).join(' - ');
        return (
          <Row>
            <Col>
              { locationNames }
            </Col>
          </Row>
        );
      }
      const name = `${params.data.customer_first_name} ${
        !isEmpty(params.data.customer_last_name) ? params.data.customer_last_name : ""
      }`;
      return (
        <div>
          <Popover title={ I18n.t('general.customer_details') } content={ <ConsigneeDetails record={ params.data } key={ `consigneeDetails${params.data.id}` } /> }>
            { isEmpty(name) ? 'N/A' : name }
          </Popover>
        </div>
      );
    },
    cellStyle: {
      textAlign: 'left',
    },
  };
}

export const originFilter = (data) => {
  const sourcePoint =
    data?.origin && Object.keys(data.origin).length !== 0
      ? data.origin
      : data.warehouse_address;

  const destinationPoint =
    data?.destination && Object.keys(data.destination).length !== 0
      ? data.destination
      : data.customer_address;
  return `${sourcePoint?.city || 'NA'} - ${destinationPoint?.city || 'NA'}`;

}




// Origin -> Destination column
export const originDestinationColumn = {
  headerName: 'Origin -> Destination',
  field: 'orgin_destination',
  isVisible : true,
  valueGetter: (params) => (originFilter(params.data)),
  cellRenderer: (params) => (
    <div style={ { display: 'flex' } }>
      <div>{ fetchLocAddress(params.data, 'origin') }</div>
      <span>&nbsp;{ ' - ' }&nbsp;</span>
      <div>{ fetchLocAddress(params.data, 'destination') }</div>
    </div>
  ),
  // width: 200,
};



// Origin -> Destination column Quotes
const QuotesOriginDestinationColumn = {
  headerName: 'Origin -> Destination',
  field: 'city',
  isVisible : true,
  valueGetter: (params) => (originFilter(params.data)),
  cellRenderer: (params) => (
    <div style={{ display: 'flex' }}>
            <div>{setAddress(params.data, 'origin')}</div>
            <span>&nbsp;{' - '}&nbsp;</span>
            <div>{setAddress(params.data, 'destination')}</div>
          </div>
    
  ),
  // width: 200,
};

// City column
export const cityColumn = (incomingVal = null) => ({
  headerName: 'City',
  field: 'cities',
  isVisible : true,
  floatingFilterComponentParams: { suppressFilterButton: true },
  valueGetter: (params) => {
    if (incomingVal === "preplan") {
      if (["T", "LH"].includes(params.data.type_of_order)) {
        const locations = _.get(params, 'data.locations', []);
        const pickup = locations.find(location => location.type_of_loc === "PICKUP");
        const delivery = locations.find(location => location.type_of_loc === "DELIVERY");
        const pickupCity = pickup ? pickup.l_address.city.toLowerCase() : "";
        const dropCity = delivery ? delivery.l_address.city.toLowerCase() : "";
        return { pickupCity, dropCity };
      } else {
        return _.get(params, 'data.customer_city', '');
      }
    } else {
      return _.get(params, 'data.customer_address.city', '');
    }
  },
  cellRenderer: (params) => {
    const { pickupCity = '', dropCity = '' } = params.value || {};
    const tootlTipDelay = AppConfig.tooltipDelay;
    // Determine if we're dealing with a preplan case
    if (incomingVal === "preplan" && ["T", "LH"].includes(params.data.type_of_order)) {
      return (
        <>
          {pickupCity.length > 0 && (
            <Tooltip
              title={{ P: pickupCity }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placement="topRight"
              mouseEnterDelay={tootlTipDelay}
            >
              {`P: ${pickupCity}`}
            </Tooltip>
          )}
          {pickupCity.length > 0 && dropCity.length > 0 && <>&nbsp;{' - '}&nbsp;</>}
          {dropCity.length > 0 && (
            <Tooltip
              title={{ D: dropCity }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placement="topRight"
              mouseEnterDelay={tootlTipDelay}
            >
              {`D: ${dropCity}`}
            </Tooltip>
          )}
        </>
      );
    } else {
      // For non-preplan or non T/LH orders, display customer city
      const customerCity = params.value;
      return (
        <Tooltip
          title={customerCity}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          placement="topRight"
          mouseEnterDelay={tootlTipDelay}
        >
          {customerCity}
        </Tooltip>
      );
    }
  },
  cellStyle: {
    textAlign: 'left',
  },
});

// Warehouse column
export const warehouseColumn = {
  headerName: 'Warehouse',
  field: 'warehouse',
  isVisible : true,
  sortable:false,
  valueGetter: (params) => _.get(params, 'data.warehouse_address.city', ''),
  cellStyle: {
    textAlign: 'left',
  },
};

const priorityColumn = {
  headerName: 'Priority',
  field: 'priority',
  sortable: true,
  filter: false,
  isVisible: true,
  cellRenderer: (params) => {
    if (params.data.priority === null || params.data.priority === false) {
      return "No";
    }
    return (
      <span>
        Yes &nbsp; <Icon type="flag" style={{ color: 'red', fontSize: '15px' }} />
      </span>
    );
  },
};

// Routing column
export const routingColumn = {
  headerName: 'Routing',
  field: 'routing',
  sortable: true,
  floatingFilterComponentParams: { suppressFilterButton: true },
  isVisible : true,
  valueGetter: (params) => params.data.routing || '',
};


const CreatedByColumn = {
  headerName: 'Created By',
  field: 'created_by',
  sortable: true,
  isVisible : true,
  valueGetter: (params) => params.data.created_by || '',
};

// Street column
const streetColumn = (incomingVal = null) => ({
  headerName: 'Street',
  field: 'customer_address',
  sortable : true,
  isVisible : true,
  floatingFilterComponentParams: { suppressFilterButton: true },
  valueGetter: (params) => _.get(params, 'data.customer_address.address_line1', '') ,
  cellStyle: {
    textAlign: 'left',
  },
  // width: 140,
});

const pendingOrdersStreetColumn = () => ({
  headerName: I18n.t("general.street"),
  field: 'customer_address',
  sortable: true,
  isVisible: true,
  floatingFilterComponentParams: { suppressFilterButton: true },
  valueGetter: (params) => _.get(params, 'data.customer_address.address_line1', ''),
  cellRenderer: (params) => {
    const addressLine1 = _.get(params, 'data.customer_address.address_line1', '');
    
    return (
      <div style={{ display: "flex" }}>
        <Tooltip
          title={addressLine1}
          placement="topLeft"
          overlayStyle={{ whiteSpace: "pre-wrap" }}
        >
          <div>
            {addressLine1}
          </div>
        </Tooltip>
      </div>
    );
  },
  cellStyle: {
    textAlign: 'left',
  },
  // width: 140,
});


// Address column
const getAddressColumn = (key = null) => {
  const addressColumn =  {
    headerName: 'Address',
    field: 'full_address',
    isVisible: true,
    sortable: true,
    valueGetter: (params) => {
      const sourcePoint = getAddress(params.data, 'pickup');
      const destinationPoint = getAddress(params.data, 'delivery');
      return `${sourcePoint} - ${destinationPoint}`;
    },
    cellRenderer: (params) => {
      const sourcePoint = getAddress(params.data, 'pickup');
      const destinationPoint = getAddress(params.data, 'delivery');
      if (["LH"].includes(params.data.type_of_order)) {
        return `${sourcePoint} -> ${destinationPoint || 'NA'}`;
      } else if (params.data.type_of_order === 'T') {
        // Assuming displayTransferAddress returns a plain text address
        return displayTransferAddress(params.data, key);
      } else {
        return sourcePoint || destinationPoint || 'NA';
      }
    },
    width: 450,
    cellStyle: {
      textTransform: 'uppercase',
    },
  };
  return addressColumn;
};



const getAddressColumnForPreplan = (key = null) => {
  const addressColumn =  {
    headerName: 'Address',
    field: 'full_address',
    isVisible : true,
    valueGetter: (params) => {
      const sourcePoint = getAddressForPreplanCol(params.data, 'pickup');
      const destinationPoint = getAddressForPreplanCol(params.data, 'delivery');
      return `${sourcePoint} - ${destinationPoint}`;
    },
    cellRenderer: (params) => {
      const sourcePoint = getAddressForPreplanCol(params.data, 'pickup');
      const destinationPoint = getAddressForPreplanCol(params.data, 'delivery');
      if (["LH"].includes(params.data.type_of_order)) {
        return (
        <Popover
          content={
            <Row style={{ textAlign: 'center' }}>
              <Col><Tag color="#FF0000">{sourcePoint}</Tag></Col>
              <Col> <Icon type="arrow-down" /></Col>
              <Col><Tag color="#0A7474">{destinationPoint}</Tag></Col>
            </Row>
          }
          title="Address"
        >    <div style={{ display: 'flex' }}>
            <div>{sourcePoint}</div>
            <span>&nbsp;{' -> '}&nbsp;</span>
            <div>{destinationPoint ? destinationPoint : "NA"}</div>
          </div>
        </Popover>
        );
      } else if(params.data.type_of_order === 'T') {
        return displayTransferAddress(params.data,key)
      } else {
        return (
          <div>
            {sourcePoint ? sourcePoint : destinationPoint}
          </div>
        )
      }
    },
  
    sortable : false,
    width: 450,
    cellStyle: {
      textTransform: 'uppercase',
    },
  };
  return addressColumn;
}



// Pickup Address column
const pickupAddressColumn = {
  headerName: 'Pickup Address',
  field: 'PickupAddress', 
  isVisible : true,
  valueGetter: (params) => getAddress(params.data,'pickup'),
  // cellRenderer: (params) => (getAddress(params.data, 'pickup')),
  sortable: true,
  width: 350, 
  cellStyle: {
    textTransform: 'uppercase',
  },
};

// Delivery Address column
const deliveryAddressColumn = {
  headerName: 'Delivery Address',
  field: 'DeliveryAddress',
  isVisible: true,
  valueGetter: (params) => getAddress(params.data,'delivery'),
  cellRenderer: (params) => (getAddress(params.data, 'delivery')),
  sortable: true,
  width: 350, 
  cellStyle: {
    textTransform: 'uppercase',
  },
};

const estimateBillingColumn = (fn) => {
  return {
    headerName: 'Estimate',
    field: 'estimate',
    isVisible: true,
    valueGetter: ((params) => params.data.estimate || ''),
    cellRenderer: ((params) => {
      return (<EstimateData record={params.data} updateEstimateVal = {fn} />);
    }),
  };
};


const getSignedBolColumn = () => {
  return{
    headerName: 'BOL',
    field:"is_pod_signed_bol_exist",
    filter: false,
    isVisible: true,
    valueGetter: ((params) => params.data.is_pod_signed_bol_exist),
    cellRenderer: (params) => {
      const verifyManualBol = _.get(params, 'data.type_of_order', null) !=="T" ? _.get(params, 'data.bol_picture', null) : {
        origin: _.get(params,"data.bol_picture",null),
        destination: _.get(params,"data.delivery_bol_picture",null)
      };
      const isVerifyManualBolValid = verifyManualBol
        ? (typeof verifyManualBol === 'object'
          ? Object.values(verifyManualBol).some(value => !!value)
          : !_.isEmpty(verifyManualBol))
        : false;
      return (<div style={{ color: isVerifyManualBolValid ? 'green' : 'red', textAlign: 'center' }}>
        {isVerifyManualBolValid ? 'Exists' : 'Does Not Exist'}
      </div>)
    },
}};


// Type column
export const typeColumn = {
  headerName: 'Order Type',
  field: 'type_of_order',
  isVisible : true,
  cellRenderer: (params) => (
    <div style={ { textAlign: 'center' } } className="type-of-order-tag-container">
      <TypeOfOrder
        order={ params.data }
        orderTypeKey="type_of_order"
        relatedOrderKey="related_order"
        placement="topLeft"
        showBadge={ true }
        isInAggrid={true}
      />
    </div>
  ),
  valueGetter: (params) => AppConfig.orderTypeShortForms[params.data.type_of_order],
  cellStyle: {
    textAlign: 'left',
  },
  width: 80,
};

// Quantity column
export const quantityColumn = {
  headerName: 'Qty',
  field: 'items_count',
  type: 'numericColumn',
  isVisible : true,
  filter: 'agNumberColumnFilter',
  valueGetter: (params) => params.data.quantity || '',
  width: 100,
};

// Exception column
export const exceptionColumn = {
  headerName: 'Exception Reason',
  field: 'exception_message',
  isVisible : true,
  valueGetter: (params) => _.get(params,'data.exception_message',''),
  cellRenderer:(params) => {
    return (
      <div className="textRed">
        {params.data.exception_message}
      </div>
    )
  },
  width: 160,
};

// On Hold Reason column
const onHoldReasonColumn = {
  headerName: 'On Hold Reason',
  isVisible : true,
  field: 'on_hold_reason',
  valueGetter: (params) => _.get(params,'data.on_hold_reason',''),
  cellRenderer:(params) => {
    return (
      <div className="textRed">
        {params.data.on_hold_reason}
      </div>
    )
  },
  width: 150,
};


// Weight column
export const weightColumn = {
  headerName: 'Weight (Lbs)',
  field: 'weight',
  isVisible : true,
  type: 'numericColumn',
  filter: 'agNumberColumnFilter',
  valueGetter: (params) => params.data.weight || '',
  width:110
};

const vehicleTypeColumn = {
  headerName: 'Vehicle Type',
  field: 'vehicle_type',
  isVisible : true,
  filter: 'agNumberColumnFilter',
  valueGetter: (params) => params.data.vehicle_type || '',
  width:110
};


// lastUpdatedByColumn
const lastUpdatedByColumn = {
  headerName: I18n.t("order.last_updated_by"),
  field: 'created_by',
  isVisible : true,
  valueGetter: (params) => params.data.created_by || '',
  width:110
};


// SkidsPallets column
const skidsPallets = {
  headerName: 'Skids / Pallets',
  field: 'pallets',
  isVisible : true,
  type: 'numericColumn',
  valueGetter: (params) => params.data.pallets || '',
  width:100
};

const age = {
  headerName: 'Age',
  field: 'created_at',
  isVisible : true,
  type: 'numericColumn',
  valueGetter : (params) => moment().diff(moment(params.data.created_at), 'days'),
  cellRenderer: (params) => (
    <div className="paddingRight25">
      <span>{ moment().diff(moment(params.data.created_at), 'days') }</span>
    </div>
  ),
  cellStyle: (params) => ({
    textAlign: 'right',
  }),
}
// const quoteNoColumn = {
//   headerName: I18n.t("general.order_type"),
//   field: 'quote_no',
//   isVisible : true,
//   valueGetter: (params) => params.data.quote_no || '',
//   width: 150
// }

// SC column
const sc = {
  headerName: 'SC',
  field: 'sc',
  filter:false,
  isVisible : true,
  type: 'numericColumn',
  valueGetter: (params) => params.data.order_delivery_time || '',
  width: 100,
  comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
    if (nodeA.data.appointments && nodeA.data.appointments.length > 0 && nodeB.data.appointments && nodeB.data.appointments.length > 0) {
      const dateA = new Date(nodeA.data.appointments[ 0 ].start_datetime);
      const dateB = new Date(nodeB.data.appointments[ 0 ].start_datetime);
      return dateA.getTime() - dateB.getTime();
    }
    return 0;
  },
  cellRenderer: (params) => {
    const data = params.data;
    return (
      <div>
        {data.appointments && data.appointments.length > 0 ? (
          <Popover
            size="small"
            content={
              <div className="table-contact-info width500 fontsize12">
                <OrderAppointments
                  appointments={data.appointments || []}
                  timezone={data.tz_short_form}
                  showConfirmedTime={false}
                  locations={data.locations}
                  showPDLabels={["T", "LH"].includes(data.type_of_order) ? true : false}
                  tooltip={false}
                />
              </div>
            }
            title={I18n.t("appointmentForm.preference_title")}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          >
            <OrderAppointments
              appointments={data.appointments || []}
              timezone={data.tz_short_form}
              showConfirmedTime
              locations={data.locations}
              showPDLabels={["T", "LH"].includes(data.type_of_order) ? true : false}
              tooltip={false}
            />
          </Popover>
        ) : (
          ""
        )}
      </div>
    );
  },
  width: 120,
  align: "left",
  sortable: true,
  sortDirections: ["ascend", "descend", "ascend"],
};


// Driver column
const driverColumn = {
  headerName: 'Driver',
  field: 'driver',
  isVisible : true,
  cellRenderer: (params) => (
    <div>{ params.data.driver_name }</div>
  ),
  // width: 60,
};
const preplanstatusColumn = {
  headerName: 'Status',
  field: 'status',
  filter:true,
  isVisible : true,
  width: 130,
};

// Status column
// const statusColumn = {
//   headerName: 'Status',
//   field: 'status',
//   filter:false,
//   isVisible : true,
//   sortable: true,
//   cellRenderer: (params) => (
//     <div>{ retrieveOrderValue(params.data, 'status', true,"beta") }</div>
//   ),
//   valueGetter: (params) => {
//     const record = params.data;
//     if (['T', 'LH'].includes(record.type_of_order)) {
//       return `${record?.origin?.status || ''}${record?.destination?.status || ''}`;
//     } else {
//       return record.status;
//     }
//   },
//   width:250,
// };

const statusColumn = {
  headerName: 'Status',
  field: 'status',
  filter: false,
  isVisible: true,
  sortable: true,
  cellRenderer: (params) => {
    const statusValue = retrieveOrderValue(params.data, 'status', true, "beta");
    return statusValue || '';
  },
  valueGetter: (params) => {
    const record = params.data;
    if (['T', 'LH'].includes(record.type_of_order)) {
      return `${record?.origin?.status || ''}${record?.destination?.status || ''}`;
    } else {
      return record.status;
    }
  },
  width: 250,
};


// Age column
const ageColumn = {
  headerName: 'Age',
  field: 'created_at',
  type: 'numericColumn',
  isVisible : true,
  filter: 'agNumberColumnFilter',
    // use parseint
  valueGetter : (params) => moment().diff(moment(params.data.created_at), 'days'),  
  cellRenderer: (params) => (
    <div>
      <span>{ moment().diff(moment(params.data.created_at), 'days') }</span>
    </div>
  ),
  cellStyle: (params) => ({
    textAlign: 'right',
  }),
  width: 95,
};

const losColumn = (field= "los" , incomingVal = null) => ({
  headerName: 'LOS',
  field,
  isVisible : true,
  floatingFilterComponentParams: { suppressFilterButton: true },
  valueGetter: (params) =>{
    params.data.levelOfService} ,
  cellRenderer: (params) => (
    incomingVal === "preplan"
      ? <div>{ params.data.levelOfService }</div>
      : <div>{ params.data.los_name }</div>
  ),
  width: 110,
});

// Zones column
const zonesColumn = {
  headerName: 'Zones',
  field: 'zone_name', 
  floatingFilterComponentParams: { suppressFilterButton: true },
  // filter: 'agTextColumnFilter',
  isVisible : true,
  valueGetter: (params) => {
    const flattenZones = (zones) => {
      return zones?.map((zone) => {
        if (typeof zone === 'object') {
          return flattenZones(Object.values(zone))?.join(', ');
        }
        return zone;
      });
    };

    const allZones = flattenZones(params.data.order_zone_name)?.join(', ');
    return allZones;
  },
  cellRenderer: (params) => (
    <ZonesDisplay data={params.data} />
  ),
    // width: 152,
};

const getPendingOrderNoColumn = (showTriggerEdit, handleEditOrder, refreshCallback, showDetailsLink) => {
  return {
    headerName: I18n.t("order.order_no"),
    field: 'customer_order_number',
    isVisible : true,
    sortable : true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    valueGetter: (params) => params.data.customer_order_number|| '',
    cellRenderer: (params) => {
      const data=params.data;
      const customer_order_number =data.customer_order_number
      const textColor = showTriggerEdit && data.status === "EXCEPTION" ? "textRed" : "";

      return (
        <Row type="flex" align="middle" gutter={4}>
          <Col>
            <TypeOfOrder
              order={data}
              orderTypeKey="type_of_order"
              relatedOrderKey="related_order"
              placement="topLeft"
              showBadge={true}
            />
          </Col>
          <Col>
            {checkServiceExistance("COS") ? (
              showTriggerEdit ? (
                <Fragment>
                  <Link className={textColor} onClick={() => handleEditOrder(data.id)}>
                    {data.status === "EXCEPTION" ? (
                      <Popover
                        title="Exception"
                        content={!isEmpty(data.exception_message) ? data.exception_message : "Reason Not Available"}
                        overlayClassName="popoverWidth"
                      >
                        {data}
                      </Popover>
                    ) : (
                      data
                    )}
                  </Link>
                  &nbsp;
                  <Copyable text={customer_order_number} />
                </Fragment>
              ) : showDetailsLink !== false ? (
                <CustomerOrderDetails
                  order_no={customer_order_number}
                  order={{
                    id: data.id,
                    customer_order_number: customer_order_number,
                  }}
                  showEdit={!["REJECTED"].includes(data.status)}
                  editClick={() => handleEditOrder(data.id)}
                  refreshCallback={refreshCallback}
                />
                // <div>999999</div>
              ) : (
                <span className={textColor}>{customer_order_number}</span>
              )
            ) : (
              <span className={textColor}>{customer_order_number}</span>
            )}
          </Col>
        </Row>
      );
    },
  };
};

export const getOrderNoColumn = (showTriggerEdit, handleEditOrder, refreshCallback, showDetailsLink,gotoOrderDetails, incomingVal = null , showEditIcon = true) => {
  const orderNoColumn = {
    headerName: 'Order No',
    field: 'customer_order_number',
    isVisible : true,
    cellRenderer: (params) => renderOrderNumType(params.data.customer_order_number, params.data, showTriggerEdit, handleEditOrder, refreshCallback, showDetailsLink , true, gotoOrderDetails, showEditIcon),
    width: 170,
  };
  if(incomingVal && (incomingVal === "preplan")){
    orderNoColumn.rowDrag = (params) => {
      return !params.data.account_limit_exceeded;
    }
  }
  return orderNoColumn;
};

export const getLinehaulOrderNumber = (gotoOrderDetails, incomingVal = null) => {
  const linehaulOrderNo = {
    headerName: 'Linehaul No',
    field: 'linehaul_order_number',
    isVisible : true,
    cellRenderer: (params) => {
      return (
        <Link onClick={() => gotoOrderDetails(params.data.linehaul_order_id)}>
          {params.data.linehaul_order_number}
        </Link>
      )

    },
    width: 170,
  };
  if(incomingVal && (incomingVal === "preplan")){
    linehaulOrderNo.rowDrag = (params) => {
      return !params.data.account_limit_exceeded;
    }
  }
  return linehaulOrderNo;
};

export const getHawbColumn = (showTriggerEdit, handleEditOrder, refreshCallback, incomingVal = null, showEditIcon = true) => {
  const mawbColumn = {
    headerName: 'HAWB',
    field: 'hawb',
    isVisible : true,
    cellRenderer: (params) => <OrderFieldDisplay data={params.data} showEditIcon={showEditIcon} toDisplay="hawb" showTriggerEdit={showTriggerEdit} editClick={() => handleEditOrder(params.data.id)} refreshCallback={refreshCallback} charsToLimit={null} />,
    width: 140,
  };
  if(incomingVal && (incomingVal === "preplan")){
    mawbColumn.rowDrag = (params) => {
      return !params.data.account_limit_exceeded && params.data.hawb && params.data.hawb.length;
    }
  }
  return mawbColumn;
};

const getHawbNoColumn = (showTriggerEdit,showDetailsLink, handleEditOrder,gotoOrderDetails) => {
  const hawbColumn = {
    headerName: 'HAWB',
    field: 'hawb',
    isVisible : true,
    cellRenderer: (params) => {
      return (
        <Col>
                {showTriggerEdit ? (
                  <Row type="flex" gutter={8}>
                  <Col>
                  <Link onClick={() => handleEditOrder(params.data.id)}>
                    {ellipseText(params.data.hawb, null, true, null, false)}
                  </Link>
                  </Col>
                  <Col>
                  {params.data.hawb && (
                    <Copyable text={params.data.hawb}/>
                  )}
                  </Col>
                  </Row>
                ) : showDetailsLink !== false ? (
                  <Row type="flex" gutter={8}>
                  <Col>
                  <Link onClick={() => gotoOrderDetails(params.data.id)}>
                    {ellipseText(params.data.hawb, null, true, null, false)}
                  </Link>
                  </Col>
                  <Col>
                  {params.data.hawb && (
                    <Copyable text={params.data.hawb}/>
                  )}
                  </Col>
                  
                  </Row>
                ) : (
                  <></>
                )}
              </Col>
      )
    },
    width: 140,
  };
  return hawbColumn;
}; 

export const getMawbNoColumn = (showTriggerEdit,showDetailsLink, handleEditOrder,gotoOrderDetails) => {
  const mawbColumn = {
    headerName: 'MAWB',
    field: 'mawb',
    isVisible : true,
    cellRenderer: (params) => {
      return (
        <Col>
                {showTriggerEdit ? (
                  <Row type="flex" gutter={8}>
                  <Col>
                  <Link onClick={() => handleEditOrder(params.data.id)}>
                    {ellipseText(params.data.mawb, null, true, null, false)}
                  </Link>
                  </Col>
                  <Col>
                  {params.data.mawb && (
                    <Copyable text={params.data.mawb}/>
                  )}
                  </Col>
                  </Row>
                ) : showDetailsLink !== false ? (
                  <Row type="flex" gutter={8}>
                  <Col>
                  <Link onClick={() => gotoOrderDetails(params.data.id)}>
                    {ellipseText(params.data.mawb, null, true, null, false)}
                  </Link>
                  </Col>
                  <Col>
                  {params.data.mawb && (
                    <Copyable text={params.data.mawb}/>
                  )}
                  </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </Col>
      )
    },
    width: 140,
  };
  return mawbColumn;
}; 



export const getMawbColumn = (showTriggerEdit, handleEditOrder, refreshCallback, incomingVal = null, showEditIcon = true) => {
  const mawbColumn = {
    headerName: 'MAWB',
    field: 'mawb',
    isVisible : true,
    cellRenderer: (params) => <OrderFieldDisplay data={params.data} toDisplay="mawb" showTriggerEdit={showTriggerEdit} showEditIcon={showEditIcon} editClick={() => handleEditOrder(params.data.id)} refreshCallback={refreshCallback} charsToLimit={null} />,
    width: 140,
  };
  if(incomingVal && (incomingVal === "preplan")){
    mawbColumn.rowDrag = (params) => {
      return !params.data.account_limit_exceeded && params.data.mawb && params.data.mawb.length;
    }
  }
  return mawbColumn;
};

const getAppointmentsColumn = (hideAppointmentdate, organizationSettings) => {
  const appointmentsColumn = {
    headerName: 'Appointments',
    field: 'appointment',
    filter: false,
    isVisible: true,
    sortable: true,
    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
      const dateA = nodeA.data.appointments[ 0 ]?.start_datetime;
      const dateB = nodeB.data.appointments[ 0 ]?.start_datetime;
      if (!dateA) return 1;
      if (!dateB) return -1;
      return new Date(dateA) - new Date(dateB);
    },
    valueGetter: (params) => {
      return params.data.appointments[ 0 ]?.start_datetime;
    },
    cellRenderer: (params) => (
      <AppointmentDisplay
        data={params.data}
        hideAppointmentdate={hideAppointmentdate}
        organizationSettings={organizationSettings}
      />
    ),
    width: 190,
  };

  return appointmentsColumn;
};


const getVehicleTypeColumn = (vhTypes) => {
  const vehicleTypeColumn = {
    headerName: 'Vehicle Type',
    field: 'vehicle_type',
    filter: true,
    isVisible : true,
    sortable : true,
    cellRenderer: (params) => <VehicleImageData record={ params.data.vehicle_type } vhType={ vhTypes } showVehicleTitle />,
    width: 100, 
    // valueGetter: (params) => {
    //   return vhTypes[params.data.vehicle_type] || 'Unknown'; 
    // },
    
  };
  return vehicleTypeColumn;
};


const getVehicleImageColumn = (vhTypes) => {
  const vehicleImageColumn = {
    headerName: 'Vehicle Image',
    field: 'vehicle_image',
    sortable : false,
    filter: false,
    isVisible : true,
    cellRenderer: (params) => <VehicleImageData record={ params.data.vehicle_type } vhType={ vhTypes } width={ '30' } borderRadius={ '5' } align={ 'center' } showImage />,
  };
  return vehicleImageColumn;
};

export const getZipcodeColumn = (resolvedAddressCallback, incomingVal = null, setOrderInfo, orderInfo) => {
  const renderValidateLocationErr = (record, zipcode, locationId, lAddress, locationPartialMatch) => (
    <ValidateLocationErr
      record={ {
        ...record,
        zipcode,
        location_id: locationId,
        l_address: lAddress,
        location_partial_match: locationPartialMatch
      } }
      displayKey="zipcode"
      errorKey="location_partial_match"
      errorValue={ true }
      refreshCallback={ resolvedAddressCallback }
      loadMaps={ false }
      setOrderInfo={ setOrderInfo }
      orderInfo={ orderInfo }
    />
  );

  const renderZipcodePair = (prefix, zipcode, record, locationId, lAddress, locationPartialMatch) => (
    <>
      { prefix }: { renderValidateLocationErr(record, zipcode, locationId, lAddress, locationPartialMatch) }
    </>
  );

  const getZipcodes = (params) => {
    const data = params.data;
    let zipcodes = [];
  
    if (incomingVal === "preplan" && [ "T", "LH" ].includes(data.type_of_order)) {
      const locations = data.locations;
      if (locations.length === 1) {
        zipcodes.push(_.get(locations[0], 'l_address.zipcode', null));
      } else {
        const pickup = locations.find(location => location.type_of_loc === "PICKUP");
        const delivery = locations.find(location => location.type_of_loc === "DELIVERY");
        zipcodes.push(
          _.get(pickup, 'l_address.zipcode', null),
          _.get(delivery, 'l_address.zipcode', null)
        );
      }
    } else if ([ "T", "LH" ].includes(data.type_of_order)) {
      zipcodes.push(
        _.get(data, 'origin.zipcode', null),
        _.get(data, 'destination.zipcode', null)
      );
    } else {
      zipcodes.push(_.get(data, 'customer_zipcode', null));
    }
  
    // Filter out falsy values
    return zipcodes.filter(Boolean);
  };

  return {
    headerName: "Zipcode",
    field: "zipcode",
    isVisible: true,
    filter: true,
    sortable: true,
    valueGetter: (params) => getZipcodes(params).join('-'),
    cellRenderer: (params) => {
      const data = params.data;
      const zipcodes = getZipcodes(params);

      if (zipcodes.length === 1) {
        return renderValidateLocationErr(
          incomingVal === "preplan" ? data : (_.get(data, "origin", "destination")),
          zipcodes[ 0 ],
         ( _.get(data, 'cs_location_id') || _.get(data, 'locations[0].id')),
         incomingVal === "preplan" ?  ( _.get(data, 'customer_address') || _.get(data, 'locations[0].l_address')) : (_.get(data, "origin","destination")),
         incomingVal === "preplan" ?  (_.get(data, 'location_partial_match') || _.get(data, 'locations[0].location_partial_match')) : (_.get(data,"origin.location_partial_match", "destination.location_partial_match"))
        );
      } else {
        const [ pickupZipcode, dropZipcode ] = zipcodes;
        return (
          <>
            { !_.isEmpty(pickupZipcode) && renderZipcodePair(
              "P",
              pickupZipcode,
              incomingVal === "preplan" ? data : (_.get(data, "origin")),
               incomingVal === "preplan" ? ( _.get(data, 'pickup_location_id') || _.get(data, 'locations', []).find(l => l.type_of_loc === 'PICKUP')?.id) : (_.get(data,"origin.id")),
               incomingVal === "preplan" ? (_.get(data, 'origin') || _.get(data, 'locations', []).find(l => l.type_of_loc === 'PICKUP')?.l_address) : (_.get(data, "origin")),
               incomingVal === "preplan" ? (_.get(data, 'origin.location_partial_match') || _.get(data, 'locations', []).find(l => l.type_of_loc === 'PICKUP')?.location_partial_match) : (_.get(data,"origin.location_partial_match"))
            ) }
            { !_.isEmpty(pickupZipcode) && !_.isEmpty(dropZipcode) && <>&nbsp;{ " - " }&nbsp;</> }
            { !_.isEmpty(dropZipcode) && renderZipcodePair(
              "D",
              dropZipcode,
              incomingVal === "preplan" ? data : (_.get(data, "destination")),
              incomingVal === "preplan" ? (_.get(data, 'delivery_location_id') || _.get(data, 'locations', []).find(l => l.type_of_loc === 'DELIVERY')?.id) : (_.get(data,"destination.id")),
              incomingVal === "preplan" ? (_.get(data, 'destination') || _.get(data, 'locations', []).find(l => l.type_of_loc === 'DELIVERY')?.l_address) : (_.get(data, "destination")),
              incomingVal === "preplan" ? ( _.get(data, 'destination.location_partial_match') || _.get(data, 'locations', []).find(l => l.type_of_loc === 'DELIVERY')?.location_partial_match) : (_.get(data,"destination.location_partial_match"))
            ) }
          </>
        );
      }
    },
    cellStyle: {
      textAlign: "right",
    },
    width: 110,
  };
};

const setZipcodeColumn = (refreshCallback) => {
  const zipcodeColumn = {
    headerName: "Zipcode",
    field: "zipcode",
    isVisible: true,
    filter: true,
    sortable: true,
    width: 120,
    valueGetter: (params) => {
      const data = params.data;
      if (["T", "LH"].includes(data.type_of_order)) {
        const originZipcode = _.get(data.origin, 'zipcode', '') || '';
        const destinationZipcode = _.get(data.destination, 'zipcode', '') || '';
        return `P: ${originZipcode || 'NA'} - D: ${destinationZipcode || 'NA'}`;
      } else {
        return data.customer_zipcode || 'NA';
      }
    },
    cellRenderer: (params) => {
      const data = params.data;
      if (["T", "LH"].includes(data.type_of_order)) {
        const originZipcode = _.get(data.origin, 'zipcode', '') || '';
        const destinationZipcode = _.get(data.destination, 'zipcode', '') || '';
        return (
          <>
            P:{" "}
            {originZipcode ? (
              <ValidateLocationErr
                record={{
                  ...data,
                  zipcode: originZipcode,
                  location_id: data.origin.location_id,
                  l_address: data.origin,
                  location_partial_match: data.origin.location_partial_match
                }}
                displayKey={"zipcode"}
                errorKey={"location_partial_match"}
                errorValue={true}
                refreshCallback={refreshCallback}
                loadMaps={true}
              />
            ) : "NA"}
            &nbsp;{" - "}&nbsp;
            D:{" "}
            {destinationZipcode ? (
              <ValidateLocationErr
                record={{
                  ...data,
                  zipcode: destinationZipcode,
                  location_id: data.destination.location_id,
                  l_address: data.destination,
                  location_partial_match: data.destination.location_partial_match
                }}
                displayKey={"zipcode"}
                errorKey={"location_partial_match"}
                errorValue={true}
                refreshCallback={refreshCallback}
                loadMaps={true}
              />
            ) : "NA"}
          </>
        );
      } else {
        return (
          <div>
            <ValidateLocationErr
              record={{
                ...data,
                zipcode: data.customer_zipcode,
                location_id: data.cs_location_id,
                l_address: data.customer_address,
                location_partial_match: data.location_partial_match
              }}
              data={data}
              displayKey={"zipcode"}
              errorKey={"location_partial_match"}
              errorValue={true}
              refreshCallback={refreshCallback}
              loadMaps={true}
            />
          </div>
        );
      }
    }
  };
  return zipcodeColumn;
};

const getOrdersLosColumn = () => {
  const losColumn = {
    headerName: "LOS",
    field: "los",
    isVisible: true,
    filter: true,
    sortable: true,
    width: 120,
    valueGetter: (params) => {
      const record = params.data;
      if (["T", "LH"].includes(record.type_of_order)) {
        const originLos = record?.origin?.level_of_service;
        const destinationLos = record?.destination?.level_of_service;
        return `P: ${originLos || 'None'} - D: ${destinationLos || 'None'}`;
      } else {
        return record.levelOfService || 'None';
      }
    },
    cellRenderer: (params) => {
      const record = params.data;
      if (["T", "LH"].includes(record.type_of_order)) {
        const originLos = record?.origin?.level_of_service;
        const destinationLos = record?.destination?.level_of_service;
        return (
          <>
            P: {originLos || 'None'}
            &nbsp;-&nbsp;
            D: {destinationLos || 'None'}
          </>
        );
      } else {
        return record.levelOfService || 'None';
      }
    }
  };
  return losColumn;
};



const getCompanyNameColumn = (navigateToAccount) => {
  const companyNameColumn = {
    headerName: 'Company Name',
    field: 'company_name',
    isVisible : true,
    sortable : true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    valueGetter: (params) => params.data.company_name || '',
    cellRenderer: (params) => {
      const hasAccess = checkServiceExistance([ 'OPSAS', 'ACNF' ], 'ALL');
      return hasAccess ? (
        <Link 
          className={hasAccess ? 'anchor_cursor' : ''} 
          onClick={() => { if (hasAccess) { navigateToAccount(params.data.account_code); } }}
        >
          {params.data.company_name}
        </Link>
      ) : (
        params.data.company_name
      );
      
    },
    // width: 200,
  };
  return companyNameColumn;
};

const getCompanyNameColumnForPreplan = (navigateToAccount) => {
  const companyNameColumn = {
    headerName: 'Company Name',
    field: 'company_name',
    isVisible : true,
    sortable : false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    valueGetter: (params) => {
      if (["T", "LH"].includes(params.data.type_of_order)) {
        const deliveryLocation = params.data.locations.find(location => location.type_of_loc === "DELIVERY");
        const pickupLocation = params.data.locations.find(location => location.type_of_loc === "PICKUP");
        const pickupCompanyName = pickupLocation && !_.isEmpty(pickupLocation.company_name) ?  pickupLocation.company_name : "NA";
        const deliveryCompanyName = deliveryLocation && !_.isEmpty(deliveryLocation.company_name) ?  deliveryLocation.company_name : "NA";
        return { pickupCompanyName, deliveryCompanyName };
      } else {
        return params.data.locations[0].company_name || '';
      }
    },
    cellRenderer: (params) => {
      const { pickupCompanyName = '', deliveryCompanyName = '' } = params.value || {};
      if(["T", "LH"].includes(params.data.type_of_order)) {
        return (
          <>
            {pickupCompanyName.length > 0 && (
              <span>
                P: {pickupCompanyName}
              </span>
            )}
            {pickupCompanyName.length > 0 && deliveryCompanyName.length > 0 && <>&nbsp;{' - '}&nbsp;</>}
            {deliveryCompanyName.length > 0 && (
              <span>
                D: {deliveryCompanyName}
              </span>
            )}
          </>
        );
      } else {
        return (
          <div>
            {params.data.locations[0].company_name || ''}
          </div>
        );
      }
    },
  };
  return companyNameColumn;
};

export const getAccountCodeColumn = (navigateToAccount, screenFrom = "default") => {
  const accountCodeColumn = {
    headerName: 'Account Code',
    field: 'account_code',
    isVisible : true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    valueGetter: (params) => params.data.account_code || '',
    cellRenderer: (params) => {
      const hasAccess = checkServiceExistance(['OPSAS', 'ACNF'], 'ALL');
      const excludeFromScreens = ["preplan", "dispatch"];
      return (
        <div className="ellipsis-text">
          { hasAccess && !excludeFromScreens.includes(screenFrom) ? (
            <Link className={ hasAccess ? 'anchor_cursor' : '' } onClick={ () => { if (hasAccess) { navigateToAccount(params.data.account_code); } } }>
              { params.data.account_code }
            </Link>
          ) : params.data.account_code }
        </div>
      );
    },
    cellStyle: {
      textAlign: 'left',
    },
    width: 120,
  };
  return accountCodeColumn;
};

const accountLimitExceededColumn = {
  headerName: '',
  field: 'account_limit_exceeded',
  isVisible : true,
  cellRenderer: (params) => {
    const { account_limit_exceeded } = params.data;
    if (!account_limit_exceeded) {
      return null;
    }
    return (
      <div>
        <Tooltip title={ I18n.t("order.account_limit_exceeded") } placement="left">
          <Icon type="info-circle" style={ { color: "red" } } />
        </Tooltip>
      </div>
    );
  },
  filter: false,
  sortable: false,
  resizable: false,
  pinned: "right",
  suppressMenu: true,
  width: 48,
};


const getPreplanLosColumn = () => {
  const losColumn = {
    headerName: "LOS",
    field: "los",
    isVisible: true,
    filter: true,
    sortable: true,
    width: 120,
    valueGetter: (params) => {
      const data = params.data;
      if (["T", "LH"].includes(data.type_of_order)) {
        const pickup = data.locations.find(
          (loc) => loc.type_of_loc === "PICKUP"
        );
        const drop = data.locations.find(
          (loc) => loc.type_of_loc === "DELIVERY"
        );
        const pickupLOS = pickup?.los_code ? pickup?.los_code : "";
        const dropLOS = drop?.los_code ? drop.los_code : "";
        return `P: ${pickupLOS || 'None'} - D: ${dropLOS || 'None'}`;
      } else {
        return data.levelOfService || 'None';
      }
    },
    cellRenderer: (params) => {
      const data = params.data;
      if (["T", "LH"].includes(data.type_of_order)) {
        const pickup = data.locations.find(
          (loc) => loc.type_of_loc === "PICKUP"
        );
        const drop = data.locations.find(
          (loc) => loc.type_of_loc === "DELIVERY"
        );
        const pickupLOS = pickup?.los_code ? pickup?.los_code : "";
        const dropLOS = drop?.los_code ? drop.los_code : "";
      return (
          <>
            P: {pickupLOS || 'None'}
            &nbsp;-&nbsp;
            D: {dropLOS || 'None'}
          </>
        );
      } else {
        return data.levelOfService || 'None';
      }
    }
  };
  return losColumn;
};

const getPreplanActionColumn = (currentOrgCode) => {
  return {
    headerName: "",
    field: "preplan_order_actions",
    pinned: "right",
    cellRenderer: (params) => {
      const { data, rowIndex } = params;
      const index = rowIndex;
      const record = data;

      return (
        <div style={{ width: '100%', overflow: 'hidden' }}>
          <Row gutter={ 8 } type="flex" align="middle" justify="center">
            <Col span={ currentOrgCode === 'TA' ? 5 : 8 }>
              <PreplanOrderAccessorial
                record={ record }
                placement={
                  index !== 0 && index === params.data.length - 1
                    ? "topRight"
                    : "left"
                }
                showPopupContainer={ false }
              />
            </Col>
            <Col span={ currentOrgCode === 'TA' ? 5 : 8 }>
              <PreplanOrderNotes
                record={ record }
                placement={
                  index !== 0 && index === params.data.length - 1
                    ? "topRight"
                    : "left"
                }
                showPopupContainer={ false }
              />
            </Col>
            { currentOrgCode === 'TA' && (
              <>
                <Col span={ 5 } style={ { paddingTop: 2 } }>
                  <CustomerOrderDetails
                    order_no={ record.customer_order_number }
                    order={ {
                      id: record.id,
                      customer_order_number: record.customer_order_number,
                    } }
                    showEdit={ false }
                    showIcon={ true }
                    eyeIconStyle={ { fontSize: 15 } }
                  />
                </Col>
                <Col span={ 4 }>
                  <OrderAttachments
                    record={ record }
                    placement={
                      index !== 0 && index === params.data.length - 1
                        ? "topRight"
                        : "left"
                    }
                    showPopupContainer={ false }
                  />
                </Col>
              </>
            ) }
            { checkServiceExistance("NRURO") && (
              <Col span={ currentOrgCode === 'TA' ? 5 : 8 }>
                <div>
                  { record.account_limit_exceeded && (
                    <Tooltip
                      title={ I18n.t("order.account_limit_exceeded") }
                      placement="left"
                    >
                      <Icon type="info-circle" style={ { color: "red" } } />
                    </Tooltip>
                  ) }
                </div>
              </Col>
            ) }
          </Row>
        </div>
      );
    },
    width: currentOrgCode === 'TA' ? 120 : 90,
    filter: false,
    sortable: false,
    resizable: false,
  };
};

const preplanOrderActionColumns = {
  headerName: "",
  field: "preplan_order_actions",
  pinned: "right",
  cellRenderer: (params) => {
    const { data, rowIndex } = params;
    const index = rowIndex;
    const record = data;

    return (
      <div>
        <Row gutter={ 12 } type="flex">
          <Col span={ 5 }>
            <PreplanOrderAccessorial
              record={ record }
              placement={
                index !== 0 && index === params.data.length - 1
                  ? "topRight"
                  : "left"
              }
              showPopupContainer={ false }
            />
          </Col>
          <Col span={ 5 }>
            <PreplanOrderNotes
              record={ record }
              placement={
                index !== 0 && index === params.data.length - 1
                  ? "topRight"
                  : "left"
              }
              showPopupContainer={ false }
            />
          </Col>
          <Col span={ 5 } style={{ paddingTop: 2}}>
              <CustomerOrderDetails
                order_no={  record.customer_order_number }
                order={ {
                  id: record.id,
                  customer_order_number: record.customer_order_number,
                } }
                showEdit={ false }
                showIcon={true}
                eyeIconStyle={{ fontSize: 15 }}
              />
          </Col>
          <Col span={ 4 }>
            <OrderAttachments
              record={ record }
              placement={
                index !== 0 && index === params.data.length - 1
                  ? "topRight"
                  : "left"
              }
              showPopupContainer={ false }
            />
          </Col>
          { checkServiceExistance("NRURO") && (
            <Col span={ 5 }>
              <div>
                {record.account_limit_exceeded && (
                  <Tooltip
                    title={ I18n.t("order.account_limit_exceeded") }
                    placement="left"
                  >
                    <Icon type="info-circle" style={ { color: "red" } } />
                  </Tooltip>
                )}
              </div>
            </Col>
          ) }
          
        </Row>
      </div>
    );
  },
  width: 140,
  filter: false,
  sortable: false,
  resizable: false,
}

export const getAccountNameColumn = (navigateToAccount, screenFrom = "default") => {
  const accountNameColumn = {
    headerName: 'Account',
    field: 'account_name',
    isVisible: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    valueGetter: (params) => params.data.account_name || '',
    cellRenderer: (params) => {
      const hasAccess = checkServiceExistance(['OPSAS', 'ACNF'], 'ALL');
      const excludeFromScreens = ["preplan", "dispatch"];
      const accountName = params.data.account_name || '';
      return hasAccess && !excludeFromScreens.includes(screenFrom) 
        ? accountName 
        : accountName;
    },
    cellStyle: {
      textAlign: 'left',
    },
    width: 160,
  };

  return accountNameColumn;
};


const getAccessorialsCols = (props) => {
  const {editClick,cloneClick,deleteClick,doConfig,showDescription} = props;
  const AccessorialActionColumn = getAccessorialActionColumn(editClick,doConfig,cloneClick,deleteClick)
  // const DescriptionColumn = descriptionCol(showDescription)
  const AccessorialCols = [
    getNameColumn,
    getCodeColumn,
    getGlCodeColumn,
    getAccessibleToColumn,
    X12EdiCode,
    sources,
    AccessorialActionColumn
  ]
  return AccessorialCols
}

// Charted Accounts columns

const accountNameColumn = {
  headerName: 'Account Name',
  field: 'account_name',
  isVisible : true,
  resizable: false,
  valueGetter: (params) => params.data.account_name || '',
  cellRenderer : (params) => {
    if (!params.value) {
      return null;
    }
    return (
      <div>
        {params.value}
      </div>
    );
  }}

const accountNo = {
  headerName: 'Account No',
  field: 'account_number',
  isVisible : true,
  resizable: false,
  valueGetter: (params) => params.data.account_number || '',
  cellRenderer : (params) => {
    if (!params.value) {
      return null;
    }
    return (
      <div>
        {params.value}
      </div>
    );
  }
}

// const transactionType = {
//   headerName: 'Transcation Type',
//   field: 'transaction_type',
//   isVisible : true,
//   resizable: false,
//   width: 200,
//   valueGetter: (params) => params.data.transaction_type || '',
//   cellRenderer : (params) => {
//     if (!params.value) {
//       return null;
//     }
//     return (
//       <div>
//         {params.value}
//       </div>
//     );
//   }}

// const coaCategory = {
//   headerName: 'COA Category',
//   field: 'coa_category',
//   isVisible : true,
//   width: 200,
//   resizable: false,
//   valueGetter: (params) => params.data.coa_category || '',
//   cellRenderer : (params) => {
//     if (!params.value) {
//       return null;
//     }
//     return (
//       <div>
//         {params.value}
//       </div>
//     );
//   }
// }

const accountDesc = {
  headerName: 'Description',
  field: 'description',
  isVisible : true,
  resizable: false,
  width: 300,
  valueGetter: (params) => params.data.description || '',
  cellRenderer : (params) => {
    if (!params.value) {
      return null;
    }
    return (
      <div>
        {params.value}
      </div>
    );
  }};

// charted accounts columns ends here

const nameColumn = {
  headerName: 'Name',
  field: 'name',
  sortable: true,
  floatingFilter: true,
  filter: true,
  width: 200,
  suppressMenu: true,    
  cellRenderer: (params) => {
    const { value } = params;
    if (!value) {
      return null;
    }
    return (
      <Tooltip title={value}>
        <div>{value}</div>
      </Tooltip>
    );
  },
} 
const codeColumn = 
{
  headerName: 'Code',
  field: 'location_code',
  sortable: true, 
  width: 200,
  floatingFilter: true,
  filter: true,
  suppressMenu: true,    
}
const addColumn = 
{
  headerName: 'Address',
  field: 'l_address',
  width: 400,
  sortable:false,
  filter : false,
  suppressMenu: true,    
  cellRenderer: (params) => {
    const { _id, ...addressValues } = params.value;
    const address = Object.values(addressValues).join(', ');
    return (
      <Tooltip title={address}>
        <div>{address}</div>
      </Tooltip>
    );
  },
}

const emailCol = {
  headerName: 'Email',
  field: 'email',
  sortable: true,
  width: 200,
  suppressMenu: true,    
  valueGetter: (params) => params.data.email,
  floatingFilter: true,
  filter: true,
  cellRenderer: (params) => {
    const { value } = params;
    if (!value) {
      return null;
    }
    return (
      <Tooltip title={value}>
        <div>{value}</div>
      </Tooltip>
    );
  },
}
const contactNameColumn = {
  headerName: 'Contact Name',
  field: 'first_name',
  sortable: true,
  width: 200,
  suppressMenu: true,    
  floatingFilter: true,
  valueFormatter: (params) => { formatFullName(params.value); },
  filter: true,
}


const contactCol = { headerName: 'Phone',
  field: 'phone',
  sortable: true, 
  floatingFilter: true, 
  filter: true,
  suppressMenu: true,    
 }
 const trackLinkColumn = {
  headerName: I18n.t("location.track_link"),
  field: "link",
  width: 200,
  sortable: true,
  suppressMenu: true,    
  valueGetter: (params) => {
    const organization_id = userStore.getStateValue("selectedOrg");
    return `${window.location.origin}/drivers_tracking/track_driver?organization_id=${userStore.getStateValue(
      "selectedOrg"
    )}&location_code=${params.data.location_code}`;
  },
  floatingFilter: true,
  filter: false,
  width: 200,
  cellRenderer: TrackLinkRenderer,
  cellRendererParams: {
    tooltipText: I18n.t("location.track_link"),
    color: "#1890ff",
    showTooltip: true,
  },
}

const getDelivery = (showDeliveryZones) => {
 const deliveryZonesCol = {
  headerName: I18n.t("location.delivery_zones"),
  field: "zones",
  width: 200,
  sortable: false,
  floatingFilter: false,
  filter: false,
  suppressMenu: true,    
  cellRenderer: (id,params) => (
    <span>
      {checkServiceExistance("DZI") && (
        <Tooltip title="Click to manage Delivery Zones">
          <Icon type="global" onClick={() => showDeliveryZones(id)} />
        </Tooltip>
      )}
    </span>
  ),
}
return deliveryZonesCol
}
const getAcctionCols = (handleEditClick,deleteClick,isSuperAdmin=false,required=true) => {
const actionCol = {
  headerName: 'Actions',
  field: 'id',
  sortable: false,
  floatingFilter: false,
  pinned: "right",
  width: 100,
  suppressMenu: true,
  resizable: required,
  cellRenderer: (params) => (
    <div className='line-actions' style={{ width: '100%' }}>
      <Row type="flex" align="middle">
        <Col>
          {checkServiceExistance('AU') && (
            <Tooltip title={I18n.t("general.edit")}>
              <span>
                <EditIcon handleClick={()=> handleEditClick(params)}/>
              </span>
            </Tooltip>
          )}
        </Col>
        <Col>
          {!isSuperAdmin && checkServiceExistance('AD') && (
            <Tooltip title={I18n.t("general.delete")}>
              <Popconfirm
                placement="left"
                title={I18n.t('messages.delete_confirm')}
                onConfirm={() => deleteClick(params)}
                okText="Yes"
                cancelText="No"
              >
                <span><DeleteIcon /></span>
              </Popconfirm>
            </Tooltip>
          )}
        </Col>
      </Row>
    </div >
  )

}
return actionCol
}

const getQuoteAcctionCols = (handleEditClick,deleteClick,cloneClick,validateQuoteClick,showReviews,showZips,isSuperAdmin) => {
  const actionCol = {
    headerName: '',
    field: 'id',
    sortable: false,
    floatingFilter: false,
    pinned: "right",
    width: 150,
    suppressMenu: true,
    cellRenderer: (record) => {
      const {data} = record
      return( 
        <>
      <div className='line-actions' style={{ width: '100%' }}>
        <Row type="flex" align="middle">
              {!['APPROVED', 'REJECTED'].includes(data.status) &&
              checkServiceExistance('QU') ? (
                <>
                <Col>
                  <Tooltip title={I18n.t("order.clone")}>
                    <Icon
                      type="snippets"
                      onClick={() => cloneClick(data.id)}
                      className="SnippetsIcon"
                    />
                  </Tooltip>
                </Col>
                
                <Col>
                  <Tooltip title={I18n.t('general.edit')}>
                    <EditIcon handleClick={()=> handleEditClick(data)}/>
                    &nbsp;
                  </Tooltip>
                </Col>
                </>
              ) : (
                ''
              )}
              {!isEmpty(data.quote_amount) && (
                <Col>
                  {data.status === 'PENDING' && checkServiceExistance('QOA') && (
                    <Fragment>
                      <Popconfirm
                        placement="left"
                        title={I18n.t('messages.action_confirm', {
                          action: 'approve',
                          field: 'this',
                        })}
                        onConfirm={() => validateQuoteClick(data.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Tooltip title={I18n.t('general.approve')}>
                          <Icon
                            type="check-circle"
                            className="cursorPointer textGreen"
                          />
                        </Tooltip>
                      </Popconfirm>
                    </Fragment>
                  )}
                </Col>
              )}
              {data.status === 'PENDING' && checkServiceExistance('QOD') && (
                <Col>
                  <Popconfirm
                    placement="left"
                    title={I18n.t('messages.action_confirm', {
                      action: 'reject',
                      field: 'this',
                    })}
                    onConfirm={() => deleteClick(data.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip title={I18n.t('general.reject')}>
                  <Icon
                        type="close-circle"
                        className="cursorPointer textRed"
                      />
                      &nbsp;&nbsp;&nbsp;
                    </Tooltip>
                  </Popconfirm>
                </Col>
              )}
            </Row>
      </div >
        </>
      )
  }
  
  }
  return actionCol
}
const getPendingOrdersActionColumns = (handleBillOfRating) => {
  const actionCol = {
    headerName: '',
    field: 'id',
    sortable: false,
    floatingFilter: false,
    pinned: "right",
    width: 150,
    suppressMenu: true,
    cellRenderer: (record) => {
      const { data } = record;
      return (
        <div>

          {checkServiceExistance("OBLR") && (
            <Tooltip
              title={I18n.t("BillOfRating.bill_of_rating_title")}
              placement="left"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              overlayStyle={{ marginRight: 30 }}
            >
              <Icon
                type="file-done"
                className="billOfLadingIcon"
                onClick={() => handleBillOfRating(data.id, data.customer_order_number)}
              />
            </Tooltip>
          )}
        </div>
      );
    }
  };
   return actionCol
}
const getQuoteMawbColumn = (showQuoteDetails) => {
  const quoteMawbColumn = {
    headerName: 'MAWB',
    field: 'mawb',
    isVisible: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    valueGetter: (params) => params.data.mawb || '',
    cellRenderer: (params) => {
      return params.data.mawb 
        ? setQuoteLinkColumn(params.data.mawb, params.data, showQuoteDetails, true)
        : '';
    },
    cellStyle: {
      textAlign: 'left',
    },
    width: 160,
  };
  return quoteMawbColumn;
};

const getQuoteHawbColumn = (showQuoteDetails) => {
  const quoteHawbColumn = {
    headerName: 'HAWB',
    field: 'hawb',
    isVisible: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    valueGetter: (params) => params.data.hawb || '',
    cellRenderer: (params) => {
      return params.data.hawb 
        ? setQuoteLinkColumn(params.data.hawb, params.data, showQuoteDetails, true)
        : '';
    },
    cellStyle: {
      textAlign: 'left',
    },
    width: 160,
  };
  return quoteHawbColumn;
};
const getQuoteNumberColumn = (showQuoteDetails) => {
  const quoteNumberColumn = {
    headerName: I18n.t('quotes.quote_no'),
    field: 'quote_no',
    isVisible: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    valueGetter: (params) => params?.data?.quote_order_number || '',
    cellRenderer: (params) => {
      return params?.data?.quote_order_number 
        ? setQuoteLinkColumn(params?.data?.quote_order_number, params?.data, showQuoteDetails, true)
        : '';
    },
    cellStyle: {

      textAlign: 'left',
    },
    width: 160,
  };
  return quoteNumberColumn;
};

const getQuoteEstimateColumn = (showQuoteDetails) => {
  const quoteEstimateColumn = {
    headerName: I18n.t('order.quote'),
    field: 'quote',
    isVisible: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    valueGetter: (params) => params.data.quote_amount || '',
    cellRenderer: (params) => {
      return params.data.quote_amount 
        ? setLinkEstimateColumn(params.data.quote_amount, params.data, showQuoteDetails, true)
        : '';
    },
    cellStyle: {
      textAlign: 'left',
    },
    width: 160,
  };
  return quoteEstimateColumn;
};
const getQuotesCols = (props) => {
  const {handleEditClick,deleteClick,cloneClick,validateQuoteClick,showReviews,showZips,isSuperAdmin,navigateToAccount,showQuoteDetails=true} = props;
  const actionQuoteColumns  = getQuoteAcctionCols(handleEditClick,deleteClick,cloneClick,validateQuoteClick,showReviews,showZips,isSuperAdmin)
  const quoteHawbColumn = getQuoteHawbColumn(showQuoteDetails);
  const quoteMawbColumn = getQuoteMawbColumn(showQuoteDetails);
  const accountNameColumn = getAccountNameColumn(navigateToAccount, 'quotes');
  const accountCodeColumn= getAccountCodeColumn(navigateToAccount, 'quotes');
  const quoteNumberColumn = getQuoteNumberColumn(showQuoteDetails);
  const quoteEstimateColumn = getQuoteEstimateColumn(showQuoteDetails);
  const quoteCols = [
    quoteNumberColumn,
    quoteHawbColumn,
    quoteMawbColumn,
    typeColumn,
    quoteExpiryDateColumn('Quote Date','quote_date'),
    QuotesOriginDestinationColumn,
    accountNameColumn,
    accountCodeColumn,
    quoteExpiryDateColumn('Expiry Date','expiry_date'),
    weightColumn,
    losColumn('los'),
    quoteEstimateColumn,
    statusColumn,
    lastUpdatedByColumn,
    actionQuoteColumns,
    
  ]
  return quoteCols
  
}

const getPendingOrdersCols = (props) => {
  const { showEditIcon = true, showTriggerEdit, handleEditOrder, refreshCallback, showDetailsLink, hideAppointmentdate, organizationSettings, navigateToAccount, resolvedAddressCallback, handleBillOfRating } = props;
  const actionPendingOrdersColumns  = getPendingOrdersActionColumns(handleBillOfRating)
  const orderNoColumn = getPendingOrderNoColumn(showTriggerEdit, handleEditOrder, refreshCallback, showDetailsLink);
  const companyNameColumn = getCompanyNameColumn(navigateToAccount);
  const appointmentsColumn = getAppointmentsColumn(hideAppointmentdate, organizationSettings);  
  const zipcodeColumn = getZipcodeColumn(resolvedAddressCallback);
  const accountNameColumn = getAccountNameColumn(navigateToAccount,);
  const accountCodeColumn= getAccountCodeColumn(navigateToAccount,);
  const hawbColumn = getHawbColumn(showTriggerEdit, handleEditOrder, refreshCallback, showEditIcon);
  const mawbColumn = getMawbColumn(showTriggerEdit, handleEditOrder, refreshCallback, showEditIcon);
  const reference1Column = getReferenceColumn(showTriggerEdit, handleEditOrder, refreshCallback, 'reference_1', showEditIcon);
  const reference2Column = getReferenceColumn(showTriggerEdit, handleEditOrder, refreshCallback, 'reference_2', showEditIcon);
  const consigneeColumn = getCustomerNameColumn()
  // const vehicleTypeColumn = getVehicleTypeColumn(vhTypes);

  const pendingOrdersCols = [
       checkboxColumn,
       orderNoColumn,
       pendingOrdersStreetColumn(),
       cityColumn(),
       warehouseColumn,
       routingColumn,
       statusColumn,
       companyNameColumn,
       pendingOrdersSource,
       getAddressColumn(),
       typeColumn,
       appointmentsColumn,
       consigneeColumn,
       zipcodeColumn,
       originDestinationColumn,
       accountNameColumn,
       accountCodeColumn,
       age,
       quantityColumn,
       weightColumn,
       losColumn('los'),
       zonesColumn,
       hawbColumn,
       mawbColumn,
       reference1Column,
       reference2Column,
       vehicleTypeColumn,
       actionPendingOrdersColumns,
    
  ]
  return pendingOrdersCols
}
const setQuoteLinkColumn = (data, record, showQuoteDetails, showEllipsisText = true) => {
  return (
    <Row type="flex" style={{ flexFlow: "unset" }} gutter={4} align="middle">
      <Col>
        <span
          className="anchor_cursor"
          onClick={() => {
            showQuoteDetails(record
            );
          }}
        >
          {showEllipsisText ? <Tooltip title={data}>{data}</Tooltip> : data}
        </span>
      </Col>
      {data  && (
        <Col>
          <Copyable text={data} />
        </Col>
      )}
    </Row>
  );
};
const setLinkEstimateColumn = (data, record, showQuoteDetails, showEllipsisText = true) => {
  return (
    <Row type="flex" style={{ flexFlow: "unset" }} gutter={4} align="middle">
      <Col>
        <span
          className="anchor_cursor"
          onClick={() => {
            showQuoteDetails(record.id
            );
          }}
        >
          
          {showEllipsisText ? <Tooltip title={'Quote Amount'}>$ &nbsp;{data}</Tooltip> : data}
        </span>
      </Col>
      {/* {data  && (
        <Col>
          <Copyable text={data} />
        </Col>
      )} */}
    </Row>
  );
};
const OrdersActionCols = (
  statusChange,
  handleEditOrder,
  handleBillOfRating,
  navigateToRoute,
  generateInvoice,
  handleSurveyDetails,
  currentFilter,
  screen_from,
  moveToPending,
  accountCodesWithExceededLimit,
  props
) => {
  const hasRoutesAccess = checkAccessExistance("DISPATCH");
  const currentOrg = props.currentOrg;
  const incomingOrgId = _.get(currentOrg, "code", null);
  const hillLogisticsOrgId = "HILL";

  const getActionCols = {
    headerName: "",
    field: "id",
    sortable: false,
    resizable: false,
    floatingFilter: false,
    pinned: "right",
    width: 150,
    cellRenderer: (id, params) => {
      const verifyManualBol =
        _.get(id.data, "type_of_order", null) !== "T"
          ? _.get(id.data, "bol_picture", null)
          : {
              origin: _.get(id.data, "bol_picture", null),
              destination: _.get(id.data, "delivery_bol_picture", null),
            };
      const isVerifyManualBolValid = verifyManualBol
        ? typeof verifyManualBol === "object"
          ? Object.values(verifyManualBol).some((value) => !!value)
          : !_.isEmpty(verifyManualBol)
        : false;
      return (
        <div
          className={
            currentFilter === "" ||
            currentFilter === "COMPLETED" ||
            currentFilter === "EXCEPTION"
              ? "alignLeft table-action-btns"
              : "alignCenter table-action-btns"
          }
          style={{ display: "flex", justifyContent: "space-between" }}
        >
           <div style={{ width: "30px" }}>
            {accountCodesWithExceededLimit.includes(id.data.account_code) ? (
              <Tooltip
                title={I18n.t("order.account_limit_exceeded")}
                placement="left"
              >
                <Icon type="info-circle" style={{ color: "red" }} />
              </Tooltip>
            ) : null}
          </div>
          <div style={{ width: "30px" }}>
            {checkServiceExistance("COSU") &&
              !["PENDING", "REJECTED"].includes(id.data.status) && (
                <Tooltip
                  title={I18n.t("tooltip.change_status")}
                  placement="topLeft"
                  overlayStyle={{ marginRight: 30 }}
                >
                  <SettingIcon handleClick={() => statusChange(id.data.id)} />
                </Tooltip>
              )}
          </div>
          <div style={{ width: "30px" }}>
            {![
              "INTRANSIT",
              "PICKEDUP",
              "CLOSED",
              "ARCHIVED",
              "PENDING",
              "REJECTED",
            ].includes(id.data.status) &&
              checkServiceExistance("SCA") && (
                <Tooltip title={I18n.t("tooltip.edit")} placement="topLeft">
                  <EditIcon
                    handleClick={() => {
                      handleEditOrder(id.data.id),
                        localStorage.setItem("orders-beta", false);
                    }}
                  />
                </Tooltip>
              )}
          </div>
          <div style={{ width: "30px" }}>
            {checkServiceExistance("OBLR") && (
              <Tooltip
                title={I18n.t("BillOfRating.bill_of_rating_title")}
                placement="topLeft"
                overlayStyle={{ marginRight: 30 }}
              >
                <Icon
                  type="file-done"
                  className="billOfLadingIcon"
                  onClick={() =>
                    handleBillOfRating(
                      id.data.id,
                      id.data.customer_order_number
                    )
                  }
                />
              </Tooltip>
            )}
          </div>
          <div style={{ width: "30px" }}>
            {screen_from !== "routes" &&
              id.data.nav_route_status &&
              id.data.nav_route_name && (
                <Tooltip
                  title={
                    id.data.nav_route_status
                      ? `${id.data.nav_route_status} - ${id.data.nav_route_name}`
                      : I18n.t("menu.routes")
                  }
                  placement="left"
                  overlayStyle={{
                    width: 250,
                    maxWidth: "400px",
                    marginRight: "150px",
                  }}
                  mouseEnterDelay={AppConfig.tooltipDelay}
                >
                  <BranchesIcon
                    handleClick={() =>
                      hasRoutesAccess
                        ? navigateToRoute(
                            id.data.nav_route_id,
                            id.data.nav_route_status,
                            id.data.scheduled_start_datetime_with_tz
                          )
                        : null
                    }
                  />
                </Tooltip>
              )}
          </div>
          {id.data.status === "COMPLETED" && (
            <>
              <div style={{ width: "30px" }}>
                {checkServiceExistance("COB") && (
                  <Tooltip
                    title="Starting billing process"
                    placement="left"
                    overlayStyle={{
                      width: 180,
                      maxWidth: "300px",
                      marginRight: "100px",
                    }}
                    mouseEnterDelay={AppConfig.tooltipDelay}
                  >
                    <Icon
                      type="file-pdf"
                      style={{ color: "#7c1034" }}
                      onClick={() => generateInvoice(id.data.id, "false")}
                    />
                  </Tooltip>
                )}
              </div>
              <div style={{ width: "30px" }}>
                <Tooltip
                  title={I18n.t("survey.view_submitted")}
                  placement="left"
                  mouseEnterDelay={AppConfig.tooltipDelay}
                  overlayStyle={{
                    width: 200,
                    maxWidth: "300px",
                    marginRight: "100px",
                  }}
                >
                  <FormIcon
                    handleClick={() => handleSurveyDetails(id.data.id)}
                  />
                </Tooltip>
              </div>
            </>
          )}
          <div style={{ width: "30px" }}>
            {incomingOrgId === hillLogisticsOrgId && isVerifyManualBolValid && (
              <ManualBolViewer
                verifyManualBol={verifyManualBol}
                manualBol={manualBol}
                key="manualBol"
                title={id.data}
                height={"13px"}
                width={"13px"}
              />
            )}
          </div>
        </div>
      );
    },
  };
  return getActionCols;
};


const getWarehouseCols = (props) => {
  const {editClick,deleteClick,showReviews,showZips,showDeliveryZones,isSuperAdmin} = props;
  const actionWarehouseColumns  = getAcctionCols(editClick,deleteClick,showReviews,showZips,isSuperAdmin)
  const getDeliveryZonesColumns= getDelivery(showDeliveryZones)
  // const getTrackLinkColumns = getTrackLink()
  const warehouseCols = [
    nameColumn,
    codeColumn,
    addColumn,
    emailCol,
    contactCol,
    contactNameColumn,
    trackLinkColumn,
    getDeliveryZonesColumns,
    actionWarehouseColumns
  ]
  return warehouseCols
}




const getNameColumn = {
  headerName : "Name",
  field :'name',
  valueGetter : (params => params.data.accessorial_name),
  width: 160,
}
const getCodeColumn = {
  headerName : "Code",
  field :'code',
  valueGetter : (params => params.data.accessorial_code),
  width: 160,
}
const getGlCodeColumn = {
  headerName : "GL Code",
  field :'gl_code',
  valueGetter : (params => params.data.gl_code),
  width: 160,
}

const getAccessibleToColumn = {
  headerName : "Accessible To",
  field :'accessible_to',
  valueGetter : (params => params.data.accessible_to),
  width: 160,
}
//   const  descriptionCol = (showDescription)=> {
//     if(showDescription !== false) {
//       const getDescriptionColumn = {
//         headerName : "Description",
//         field :'description',
//         valueGetter : (params => params.data.description),
//         width: 160, 
//       }
//       return getDescriptionColumn;
//     }
// }

const X12EdiCode = {
  headerName : "X12 Edi Code",
  field :'edi_code',
  valueGetter : (params => params.data.x12_code),
  width: 160,
}

const DeliverByColumn = {
  headerName : I18n.t("general.deliver_by"),
  field :'deliver_by',
  isVisible : true,
  sortable: true,
  filter: true,
  valueGetter: (params) => {
    return params.data.delivery_by;
  },
  cellRenderer: (params) => {
    const record = params.data;
    return (
      <div>
        {record.delivery_by ? 
          <DisplayTime  
            dateTimeString={record.delivery_by}
            displayWithDate={true}
            //timeZoneId={record.wh_timezone}
            dateTimeFormat={AppConfig.dateTimeFormat}
            tz_short_name={record.tz_short_form}
            isUTC={true}
          /> : 'NA'}
      </div>
    );
  },
  width: 180,
};

const CitiesColumn = {
  headerName : "City",
  field :'cities',
  isVisible : true,
  sortable: true,
  filter: true,
  valueGetter: (params) => {
    const record = params.data;
    if (record.type_of_order === "T") {
      return `P: ${record.origin?.city || 'NA'} - D: ${record.destination?.city || 'NA'}`;
    } else {
      return record.customer_city || '';
    }
  },
  cellRenderer: (params) => {
    const record = params.data;
    if (record.type_of_order === "T") {
      return `P: ${record.origin?.city || 'NA'} - D: ${record.destination?.city || 'NA'}`;
    } else {
      return record.customer_city || '';
    }
    
  },
  width: 180,
};



const sources = {
  headerName : "Sources",
  field :'sources',
  valueGetter: (params) => {
    return params.data.components ? 
    params.data.components.map((data) => data.component_name).join(', ') : 
    ''
  },
  width: 200,
}

const pendingOrdersSource = {
  headerName : "Source",
  field :'source',
  isVisible : true,
  valueGetter: (params) => {
    return params.data.source 
    // ? 
    // params.data.components.map((data) => data.component_name).join(', ') : 
    // ''
  },
  width: 200,
}


const getAccessorialActionColumn = (editClick,doConfig,cloneClick,deleteClick) => {
  const actionColumns = {
    headerName : 'Actions',
    field: 'id',
    sortable :false,
    pinned : 'right',
    width: 140,
    floatingFilter : false,
    cellRenderer : (id,params) => (
      <div className='line-actions' style={{
        width: '100%',
      }} >
        <Row type="flex" align="middle">
  
          <Col >
          {
                checkServiceExistance(['AAAAD', 'AACAA', 'AAAC'], 'ALL') && 
                <Tooltip title={ I18n.t("tooltip.setting") }>
              <SettingIcon handleClick={() => doConfig(id.data.id)}/>
              &nbsp;
              </Tooltip>
              }
              &nbsp;
          </Col>
          <Col >
              <Tooltip
                title ={ I18n.t("general.edit") }
                placement="left"
              >
                <EditIcon handleClick={() => editClick(id.data.id)}/>
              </Tooltip>
          </Col>
  
          <Col >
          {checkServiceExistance('AAAACL') && !isEmpty(id?.data?.standard_code) && id?.data?.is_clone == 'false' && (
                <Tooltip title={ I18n.t("tooltip.copy") }>
                <Icon
                  type="copy"
                  onClick={() => cloneClick(id.data.id)}
                />
                </Tooltip>
              )}
          </Col>
  
          <Col >
          {checkServiceExistance('AAD') && (!id?.data?.standard_code || 
              (id?.data?.standard_code && id.data.is_clone == 'true')) 
              && (
                <Tooltip title={ I18n.t("general.delete") }>
                <Popconfirm
                  placement="left"
                  title={I18n.t("messages.delete_confirm")}
                  onConfirm={() => deleteClick(id.data.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteIcon/>
                  &nbsp;&nbsp;&nbsp;
                </Popconfirm>
                </Tooltip>
              )}
          </Col>
        </Row>
      </div >
    )
  }
  return actionColumns;
  }

  const getReference1NoColumn = (showTriggerEdit,showDetailsLink, handleEditOrder,gotoOrderDetails) => {
    const reference1Column = {
      headerName: 'Reference 1',
      field: 'reference_1',
      isVisible : true,
      cellRenderer: (params) => {
        return (
          <Col>
                  {showTriggerEdit ? (
                    <Link onClick={() => handleEditOrder(params.data.id)}>
                      {<OrderFieldDisplay data={ params.data  } toDisplay="reference_1" charsToLimit={ null } />}
                    </Link>
                  ) : showDetailsLink !== false ? (
                    <Link onClick={() => gotoOrderDetails(params.data.id)}>
                      {<OrderFieldDisplay data={ params.data  } toDisplay="reference_1" charsToLimit={ null } />}

                    </Link>
                  ) : (
                    <></>
                  )}
                </Col>
        )
      },
      width: 140,
    };
    return reference1Column;
  }; 
  const getReference2NoColumn = (showTriggerEdit,showDetailsLink, handleEditOrder,gotoOrderDetails) => {
    const reference2Column = {
      headerName: 'Reference 2',
      field: 'reference_2',
      isVisible : true,
      cellRenderer: (params) => {
        return (
          <Col>
                  {showTriggerEdit ? (
                    <Link onClick={() => handleEditOrder(params.data.id)}>
                      {<OrderFieldDisplay data={ params.data  } toDisplay="reference_2" charsToLimit={ null } />}
                    </Link>
                  ) : showDetailsLink !== false ? (
                    <Link onClick={() => gotoOrderDetails(params.data.id)}>
                      {<OrderFieldDisplay data={ params.data  } toDisplay="reference_2" charsToLimit={ null } />}

                    </Link>
                  ) : (
                    <></>
                  )}
                </Col>
        )
      },
      width: 140,
    };
    return reference2Column;
  };

const getDispatchCols = (props) => {
  // destructuring props
  const { 
    showTriggerEdit,
    handleEditOrder,
    refreshCallback,
    showDetailsLink,
    hideAppointmentdate,
    vhTypes,
    organizationSettings,
    resolvedAddressCallback,
    navigateToAccount,
  } = props;
  const orderNoColumn = getOrderNoColumn(showTriggerEdit, handleEditOrder, refreshCallback, showDetailsLink);
  const hawbColumn = getHawbColumn(showTriggerEdit, handleEditOrder, refreshCallback);
  const mawbColumn = getMawbColumn(showTriggerEdit, handleEditOrder, refreshCallback);
  const appointmentsColumn = getAppointmentsColumn(hideAppointmentdate, organizationSettings);  
  const vehicleTypeColumn = getVehicleTypeColumn(vhTypes);
  const vehicleImageColumn = getVehicleImageColumn(vhTypes);
  const zipcodeColumn = getZipcodeColumn(resolvedAddressCallback);
  const companyNameColumn = getCompanyNameColumn(navigateToAccount);
  const accountCodeColumn = getAccountCodeColumn(navigateToAccount);
  const accountNameColumn = getAccountNameColumn(navigateToAccount);
  const customerNameColumn = getCustomerNameColumn();
  const reference1Column = getReferenceColumn(showTriggerEdit, handleEditOrder, refreshCallback, 'reference_1');
  const reference2Column = getReferenceColumn(showTriggerEdit, handleEditOrder, refreshCallback, 'reference_2');
  const dispatchCols = [
    checkboxColumn,
    orderNoColumn,
    hawbColumn,
    priorityColumn,
    mawbColumn,
    appointmentsColumn,
    customerNameColumn,
    originDestinationColumn,
    cityColumn,
    reference1Column,
    reference2Column,
    routingColumn,
    CreatedByColumn,
    vehicleTypeColumn,
    vehicleImageColumn,
    zipcodeColumn,
    companyNameColumn,
    accountCodeColumn,
    accountNameColumn,
    getAddressColumn("O&D"),
    typeColumn,
    quantityColumn,
    weightColumn,
    statusColumn,
    ageColumn,
    getOrdersLosColumn(),
    zonesColumn,
    accountLimitExceededColumn
  ];
  return dispatchCols;
}

const getPrePlanCol = (props) => {
  // destructuring props
  const { 
    showTriggerEdit,
    handleEditOrder,
    refreshCallback,
    showDetailsLink,
    hideAppointmentdate,
    vhTypes,
    organizationSettings,
    resolvedAddressCallback,
    navigateToAccount,
    refreshCells,
    showDetailIcon = false,
    setOrderInfo = () => {},
    orderInfo ,
    currentOrgCode
  } = props;

  const orderNoColumn = getOrderNoColumn(showTriggerEdit, handleEditOrder, refreshCallback, showDetailsLink , null , 'preplan');
  const hawbColumn = getHawbColumn(showTriggerEdit, handleEditOrder, refreshCallback, 'preplan');
  const mawbColumn = getMawbColumn(showTriggerEdit, handleEditOrder, refreshCallback, 'preplan');
  const vehicleTypeColumn = getVehicleTypeColumn(vhTypes);
  const vehicleImageColumn = getVehicleImageColumn(vhTypes);
  const zipcodeColumn = getZipcodeColumn(resolvedAddressCallback,"preplan" , setOrderInfo, orderInfo , refreshCells);
  const companyNameColumn = getCompanyNameColumnForPreplan(navigateToAccount);
  const accountCodeColumn = getAccountCodeColumn(navigateToAccount,"preplan");
  const accountNameColumn = getAccountNameColumn(navigateToAccount,"preplan");
  const customerNameColumn = getCustomerNameColumnForPreplan();
  const reference1Column = getReferenceColumn(showTriggerEdit, handleEditOrder, refreshCallback, 'reference_1');
  const reference2Column = getReferenceColumn(showTriggerEdit, handleEditOrder, refreshCallback, 'reference_2');
  const actionsColumn = getPreplanActionColumn(currentOrgCode);
  const prePlanCols = [
    checkboxColumn,
    orderNoColumn,
    typeColumn,
    hawbColumn,
    mawbColumn,
    cityColumn("preplan"),
    reference1Column,
    reference2Column,
    routingColumn,
    priorityColumn,
    streetColumn("preplan"),
    vehicleTypeColumn,
    vehicleImageColumn,
    customerNameColumn,
    // companyNameColumn,
    accountCodeColumn,
    accountNameColumn,
    companyNameColumn,
    getAddressColumnForPreplan("PREPLAN"),
    // pickupAddressColumn,
    // deliveryAddressColumn,
    // typeColumn,
    quantityColumn,
    weightColumn,
    skidsPallets,
    age,
    sc,
    preplanstatusColumn,
    getPreplanLosColumn(),
    zonesColumn,
    zipcodeColumn,
    actionsColumn
    // preplanOrderActionColumns
  ];
  return prePlanCols;
}




const getOrderListCols = (props) => {
    // destructuring props
    const {
      showTriggerEdit,
      handleEditOrder,
      refreshCallback,
      showDetailsLink,
      hideAppointmentdate,
      vhTypes,
      organizationSettings,
      resolvedAddressCallback,
      navigateToAccount,
      statusChange,
      handleBillOfRating,
      navigateToRoute,
      generateInvoice,
      handleSurveyDetails,
      currentFilter,
      screen_from,
      moveToPending,
      gotoOrderDetails,
      updateListEstimate,
      accountCodesWithExceededLimit
    } = props;
  
    const orderNoColumn = getOrderNoColumn(showTriggerEdit, handleEditOrder, refreshCallback, showDetailsLink,gotoOrderDetails);
    const linehaulOrderNoColumn = getLinehaulOrderNumber(gotoOrderDetails);
    // const hawbColumn = getHawbColumn(showTriggerEdit, handleEditOrder, refreshCallback);
    const hawbColumn = getHawbNoColumn(showTriggerEdit,showDetailsLink, handleEditOrder,gotoOrderDetails);
    const mawbColumn = getMawbNoColumn(showTriggerEdit,showDetailsLink, handleEditOrder,gotoOrderDetails);
    // const mawbColumn = getMawbColumn(showTriggerEdit, handleEditOrder, refreshCallback);
    const reference1Column = getReference1NoColumn(showTriggerEdit,showDetailsLink, handleEditOrder,gotoOrderDetails);
    const reference2Column = getReference2NoColumn(showTriggerEdit,showDetailsLink, handleEditOrder,gotoOrderDetails);
    const appointmentsColumn = getAppointmentsColumn(hideAppointmentdate, organizationSettings);
    const vehicleTypeColumn = getVehicleTypeColumn(vhTypes);
    const vehicleImageColumn = getVehicleImageColumn(vhTypes);
    const zipcodeColumn = setZipcodeColumn(resolvedAddressCallback,refreshCallback);
    const companyNameColumn = getCompanyNameColumn(navigateToAccount);
    const accountCodeColumn = getAccountCodeColumn(navigateToAccount);
    const accountNameColumn = getAccountNameColumn(navigateToAccount);
    const customerNameColumn = getCustomerNameColumn();
    const ActionColumns = OrdersActionCols(statusChange,handleEditOrder,handleBillOfRating,navigateToRoute,generateInvoice,handleSurveyDetails,currentFilter,screen_from,moveToPending,accountCodesWithExceededLimit, props)
    const estimateColumn  = estimateBillingColumn(updateListEstimate);
    const bolColumn = getSignedBolColumn();
  
    const addProperties = (column, isPeculiar, acceptedStatus) => ({
      ...column,
      // floatingFilter: false,
      isPeculiar,
      acceptedStatus, 
      cellStyle: (params) => {
        // Get the cell element to measure content width
        const cellElement = params.api.getCellRendererInstances({
          rowIndex: params.node.rowIndex,
          column: params.column
        })[0]?.eGui;
    
        if (cellElement && cellElement.scrollWidth > cellElement.clientWidth) {
          // Apply ellipsis style if content is overflowing
          return ellipsisStyle;
        }
    
        // Return an empty object if no overflow
        return {};
      },
      
    });
  
    const orderCols = [
      addProperties(checkboxColumn, false, []),
      addProperties(orderNoColumn, false, []),
      addProperties(linehaulOrderNoColumn, false, []),
      addProperties(typeColumn, false, []),
      addProperties(appointmentsColumn, false, []),
      addProperties(getAddressColumn("O&D"), false, []),
      addProperties(pickupAddressColumn, false, []),
      addProperties(deliveryAddressColumn, false, []),
      addProperties(originDestinationColumn, false, []),
      addProperties(zipcodeColumn, false, []),
      addProperties(accountCodeColumn, false, []),
      addProperties(accountNameColumn, false, []),
      addProperties(ageColumn, true, ["REJECTED"]), // here is peculiar is true because we're not showing age for rejected status, so its peculiar -ve case
      addProperties(quantityColumn, false, []),
      addProperties(weightColumn, false, []),
      addProperties(getOrdersLosColumn(), false, []),
      addProperties(zonesColumn, false, []),
      addProperties(hawbColumn, false, []),
      addProperties(mawbColumn, false, []),
      addProperties(reference1Column, false, []),
      addProperties(reference2Column, false, []),
      addProperties(statusColumn, false, []),
      addProperties(routingColumn, false, []),
      addProperties(CreatedByColumn, false, []),
      addProperties(vehicleTypeColumn, false, []),
      addProperties(vehicleImageColumn, false, []),
      addProperties(companyNameColumn, false, []),
      addProperties(driverColumn, false, ["COMPLETED","DISPATCHED","ASSIGNED",""]),
      addProperties(exceptionColumn, false, ["EXCEPTION"]),
      addProperties(onHoldReasonColumn, false, ["ON_HOLD"]),
      addProperties(customerNameColumn, false, []),
      addProperties(ActionColumns, false, []),
      addProperties(estimateColumn, false, []),
      addProperties(bolColumn, false, []),
      addProperties(priorityColumn, false, []),
      addProperties(DeliverByColumn, false, []),
      addProperties(CitiesColumn, false, [])
    ];
  
    return orderCols;
  }

const getDeletedOrderListCols = (props) => {
  // destructuring props
  const {
    showTriggerEdit,
    handleEditOrder,
    refreshCallback,
    showDetailsLink,
    hideAppointmentdate,
    vhTypes,
    organizationSettings,
    resolvedAddressCallback,
    navigateToAccount,
    gotoOrderDetails,
    updateListEstimate,
  } = props;

  const orderNoColumn = getOrderNoColumn(showTriggerEdit, handleEditOrder, refreshCallback, showDetailsLink, gotoOrderDetails);
  const hawbColumn = getHawbColumn(showTriggerEdit, handleEditOrder, refreshCallback);
  const mawbColumn = getMawbColumn(showTriggerEdit, handleEditOrder, refreshCallback);
  const appointmentsColumn = getAppointmentsColumn(hideAppointmentdate, organizationSettings);
  const vehicleTypeColumn = getVehicleTypeColumn(vhTypes);
  const vehicleImageColumn = getVehicleImageColumn(vhTypes);
  const zipcodeColumn = getZipcodeColumn(resolvedAddressCallback);
  const companyNameColumn = getCompanyNameColumn(navigateToAccount);
  const accountCodeColumn = getAccountCodeColumn(navigateToAccount);
  const accountNameColumn = getAccountNameColumn(navigateToAccount);
  const customerNameColumn = getCustomerNameColumn();
  const estimateColumn = estimateBillingColumn(updateListEstimate);
  const bolColumn = getSignedBolColumn();


  const deletedOrderCols = [
    checkboxColumn,
    orderNoColumn,
    typeColumn,
    appointmentsColumn,
    getAddressColumn(),
    pickupAddressColumn,
    deliveryAddressColumn,
    originDestinationColumn,
    zipcodeColumn,
    accountCodeColumn,
    accountNameColumn,
    ageColumn,
    quantityColumn,
    weightColumn,
    losColumn(),
    zonesColumn,
    hawbColumn,
    mawbColumn,
    reference1Column,
    reference2Column,
    statusColumn,
    routingColumn,
    CreatedByColumn,
    vehicleTypeColumn,
    vehicleImageColumn,
    companyNameColumn,
    driverColumn,
    onHoldReasonColumn,
    customerNameColumn,
    estimateColumn,
    bolColumn,
    priorityColumn,
    DeliverByColumn,
    CitiesColumn
  ];

  return deletedOrderCols;
}

const getChartedAccountsCols = (props) => {
  const {handleEditClick,handleDeleteClick} = props;
  const actionChartedAccountsColumns  = getAcctionCols(handleEditClick,handleDeleteClick,false,false)
  const chartedAccountsCols = [
    accountNameColumn,
    accountNo,
    accountDesc,
    actionChartedAccountsColumns
  ]
  return chartedAccountsCols
}


const dateColumn = (lable, field) => {
  return ({
    headerName: lable,
    field: field,
    isVisible : true,
    sortable:false,
    floatingFilter: false,
    valueGetter: (params) => _.get(params, `data.${field}`, '') || '',
    cellRenderer: (params) => {
      const timeString  = _.get(params, `data.${field}`, '') || '';
      return timeString ? 
      <DisplayTime 
      dateTimeString={timeString} 
      timeFormat={AppConfig.dateFormat}/> 
      : ''
    },
    cellStyle: {
      textAlign: 'left',
    },
    width: 105,
  });
}
const quoteExpiryDateColumn = (lable, field) => {
   return ({
    headerName: lable,
    field: field,
    isVisible : true,
    sortable:false,
    floatingFilter: true,
    valueGetter: (params) => _.get(params, `data.${field}`, '') || '',
    cellRenderer: (params) => {
      const timeString  = _.get(params, `data.${field}`, '') || '';
      return timeString ? 
      <DisplayTime 
      dateTimeString={timeString} 
      timeFormat={AppConfig.dateFormat}/> 
      : ''
    },
    cellStyle: {
      textAlign: 'left',
    },
    width: 105,
  });
}
const settlementDateColumn = (lable, field) => {
  return ({
    headerName: lable,
    field: field,
    isVisible : true,
    sortable:false,
    floatingFilter: false,
    valueGetter: (params) => _.get(params, `data.${field}`, '') || '',
    cellRenderer: (params) => {
      const timeString  = _.get(params, `data.${field}`, '') || '';
      return timeString ? 
      <DisplayTime 
      dateTimeString={timeString} 
      timeFormat={AppConfig.dateFormat}/> 
      : ''
    },
    cellStyle: {
      textAlign: 'left',
    },
    width: 120,
  });
}
const InvoiceApprovalDateColumn = (lable, field) => {
  return ({
    headerName: lable,
    field: field,
    isVisible : true,
    sortable:false,
    floatingFilter: false,
    valueGetter: (params) => _.get(params, `data.${field}`, '') || '',
    cellRenderer: (params) => {
      const timeString  = _.get(params, `data.${field}`, '') || '';
      return timeString ? 
      <DisplayTime 
      dateTimeString={timeString} 
      timeFormat={AppConfig.dateFormat}/> 
      : ''
    },
    cellStyle: {
      textAlign: 'left',
    },
    width: 150,
  });
}

// const QuoteDateColumn = (lable, field) => {
//   return ({
//     headerName: 'Quote Date',
//     field: 'quote_date',
//     isVisible : true,
//     sortable:false,
//     floatingFilter: false,
//     valueGetter: (params) => _.get(params, `data.${field}`, 'llll') || '',
//     cellRenderer: (params) => {
//       const timeString  = _.get(params, `data.${field}`, 'llll') || '';
//       return timeString ? 
//       <DisplayTime 
//       dateTimeString={timeString} 
//       timeFormat={AppConfig.dateFormat}/> 
//       : ''
//     },
//     cellStyle: {
//       textAlign: 'left',
//     },
//     width: 150,
//   });
// }


const renderDescription = (lable, field) => {
  return{
    headerName: lable,
    field: field,
    isVisible : true,
    sortable: false,
    valueGetter: (params) => _.get(params, `data.${field}`, '') || '',
    cellRenderer: (params) => {
      const content  = _.get(params, `data.${field}`, '') || '';
      return content ? <Tooltip title={content}>{content}</Tooltip> : ''
    },
    cellStyle: {
      textAlign: 'left',
    },
    floatingFilter: false,
    width: 140,
  };
}
 
const getChargeColumn = (decimalPoints, onChange) =>({
  headerName: `Charge (${I18n.t(
    'general.price_symbol'
  )})`,
  field: 'item_charge',
  floatingFilter: false,
  isVisible : true,
  valueGetter: (params) => _.get(params, `data.item_charge`, '') || '',
  cellRenderer: (params) => {
    const content  = _.get(params, `data.item_charge`, '') || '';
    return content ? checkNegitive(content) : "";
  },
  // editable: true,
  // cellEditorFramework: 
  // <CustomInputNumberEditor 
  // value={params.data.charge} 
  // placeholder={I18n.t('reports.chargable')}
  // decimalPoints={decimalPoints}
  // onChange={(e) => {
  //         params.setValue(e); // Update the value in the grid
  //         params.node.setDataValue(params.colDef.field, e); // Update the data
  //       }} />,
  // cellRenderer: (params) => (
  //   <InputNumber
  //     value={params.data.item_charge}
  //     size="small"
  //     className="fontSize12"
  //     // onChange={(e) =>
  //     //   onChange(params.rowIndex, 'charge', e)
  //     // }
  //     onChange={(e) => {
  //       params.setValue(e); // Update the value in the grid
  //       params.node.setDataValue(params.colDef.field, e); // Update the data
  //     }}
  //     placeholder={I18n.t('reports.chargable')}
  //     min={0}
  //     precision={decimalPoints}
  //     style={{ width: 100 }}
  //   />
  // ),
  width: 140,
});

const getSettlementDriver = (props) =>({
  headerName: 'Driver',
  field: 'driver_code',
  isVisible : true,
  cellRenderer: (params) => {
    const updateDriverData = (obj) => {
    //  // params.node.setDataValue('driver_id', obj.driver_id); // Update the data
    //   params.node.setDataValue('driver_code', obj.driver_code);
    //   props.handleUpdateRowData(params.data.id, 'driver_id', obj.driver_id)
      props.fetchData()
    }
    return (
    <div>{ params.data.driver_code } { params.data.status !== 'APPROVED' ? 
    <EditDriverDetails 
      drivers={props.drivers || []}
      defaultDriver={params.data.driver_id}
      currentData={params.data}
      updateDriverData={updateDriverData}
    />
    : ''}</div>
  )},
  floatingFilter: false,
  width: 120,
});

const getDriverSettlemetCols =  (props) => {

  const {navigateToAccount, deleteClick,rawDataSource} = props;
  const isApprovedIncluded = rawDataSource.map((data) => data.status) === "APPROVED"

  const getActionCols = (deleteClick) => {
    const actionCol = {
      headerName: 'Actions',
      field: 'id',
      sortable: false,
      floatingFilter: false,
      pinned: "right",
      width: 70,
      suppressMenu: true,
      cellRenderer: (params) => (
        <div className='line-actions' style={{ width: '100%' }}>
          <Row type="flex" align="middle">
            { params?.data?.type_of_order === 'MANUAL' && <Col>
              <Tooltip placement="topLeft" title="Delete">
                <Popconfirm
                  placement="topRight"
                  title={I18n.t('messages.delete_confirm')}
                  onConfirm={() =>
                    deleteClick(params.data.id)
                  }
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteIcon styleObj={{ fontSize: 16 }}/>
                  &nbsp;&nbsp;
                </Popconfirm>
              </Tooltip>
            </Col>
            }
          </Row>
        </div >
      )
    
    }
    return actionCol
    }
  const actionColumns  = getActionCols(deleteClick)

  // :customer_order_id, :wh_location_id, :customer_order_number, :hawb, :mawb, :account_id, :account_code, :organization_id, :settlement_date, :gross_revenue, :week_number, 
  // freight_charges: ,:charge_description, :gl_code, :item_id, :item_name, :accessorial_code, :gross_item_value, :item_value, :item_quantity, :item_type, :item_description, :invoice_line_id, 
  // :item_per_cost, :item_quantity, 
  // :location_id, :charge, :eligible, :percentage
  let  decimalPoints = window.localStorage.getItem('round_off_decimals')
  const { endUserName } = useContext(OrgContext);
  decimalPoints =  isEmpty(decimalPoints) ? decimalPoints :  AppConfig.default_decimal_points
  const chargeColumn = getChargeColumn(decimalPoints, props.onChange)
  const driverCol = getSettlementDriver(props)

  const warehouseColumn = {
    headerName: 'Warehouse',
    field: 'wh_name',
    isVisible : true,
    sortable:false,
    valueGetter: (params) => _.get(params, 'data.wh_name', '') || '',
    cellStyle: {
      textAlign: 'left',
    },
    floatingFilter: false,
    width: 120,
  };

  const vehicleTypeColumn = {
    headerName: 'Vehicle Type',
    field: 'vehicle_type',
    isVisible : true,
    sortable:false,
    valueGetter: (params) => _.get(params, 'data.vehicle_type', '') || '',
    cellStyle: {
      textAlign: 'left',
    },
    floatingFilter: false,
    width: 120,
  };

  const companyColumn = {
    headerName: 'Company',
    field: 'company_name',
    isVisible : true,
    sortable:false,
    valueGetter: (params) => _.get(params, 'data.integration_company_code', '') || '',
    cellStyle: {
      textAlign: 'left',
    },
    floatingFilter: false,
    width: 120,
  };
  const endUserColumn = {
    headerName: endUserName,
    field: 'customer_name',
    isVisible : true,
    sortable:false,
    valueGetter: (params) => _.get(params, 'data.consignee_name', '') || '',
    cellStyle: {
      textAlign: 'left',
    },
    floatingFilter: false,
    width: 120,
  };
  const chargeTypeColumn = {
    headerName: 'Charge Type',
    field: 'item_type',
    isVisible : true,
    sortable:false,
    valueGetter: (params) => _.get(params, 'data.item_type', '') || '',
    cellStyle: {
      textAlign: 'left',
    },
    floatingFilter: false,
    
  };
  const statusColumn = {
    headerName: 'Status',
    field: 'status',
    isVisible : true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    sortable: true,
    valueGetter: (params) => _.get(params, 'data.status', '') || '',
    cellStyle: {
      textAlign: 'left',
    },
    floatingFilter: false,
    
  };
  const orderColumn = {
    headerName: I18n.t('order.order_no'),
    field: 'customer_order_number',
    floatingFilter: false,
    isVisible : true,
    cellRenderer: (params) => {
      const { customer_order_id, type_of_order } = params.data;
      return customer_order_id && type_of_order !== "MANUAL" ? (
        <OrderBillingDetails
          record={{ ...params.data }}
          showActions={false}
          handleIsLosChanged={() => {}}
        />
      ) : (
        <Fragment><CopyableContent text={params.data.customer_order_number || ''} /><sup className="textRed textBold">(M)</sup></Fragment>
      );
    },
    width: 110,
  };
  const hawbColumn = {
    headerName: I18n.t('general.hawb'),
    field: 'hawb',
    isVisible : true,
    floatingFilter: false,
    cellRenderer: (params) =>
      params?.data?.hawb ? <CopyableContent text={params.data.hawb || ''} /> : '',
    width: 100,
  };
  const mawbColumn = {
    headerName: I18n.t('general.mawb'),
    field: 'mawb',
    isVisible : true,
    floatingFilter: false,
    cellRenderer: (params) =>
      params?.data?.mawb ? <CopyableContent text={params.data.mawb || ''} /> : '',
    width: 100,
  };
  const eligibleColumn = {
    headerName: `${I18n.t('reports.eligible')}* (${I18n.t(
      'general.price_symbol'
    )})`,
    floatingFilter: false,
    field: 'eligible_charge',
    isVisible : true,
    valueGetter: (params) => _.get(params, `data.eligible_charge`, '') || '',
    cellRenderer: (params) => {
      const content  = _.get(params, `data.eligible_charge`, '') || '';
      return content ? checkNegitive(content) : "";
    },
    // editable: true,
    // cellRenderer: (params) => (
    //     <Input
    //       value={params.data.eligible_charge}
    //       size="small"
    //       className="fontSize12"
    //       // onChange={(e) =>
    //       //   onChange(params.rowIndex, 'charge', e)
    //       // }
    //       onChange={(e) => {
    //         params.setValue(e.target.value); // Update the value in the grid
    //         params.node.setDataValue(params.colDef.field, e.target.value); // Update the data
    //       }}
    //       placeholder={I18n.t('reports.eligible')}
    //       min={0}
    //       precision={decimalPoints}
    //       style={{ width: 100 }}
    //     />
    //   ),
    width: 140,
  };
  const settlementPercentageColumn = {
    headerName: `${I18n.t('reports.settlement')} %`,
    floatingFilter: false,
    isVisible : true,
    field: 'settlement_percentage',
    // editable: true,
    cellRenderer: (params) => (
      _.get(params, "data.settlement_percentage", '') || ""
    ),
    width: 140,
  };
 
  /* Old setting column where arrow navigartion not working */
 /* const settlementColumn = () => ({
    headerName: `${I18n.t('reports.settlement')} (${I18n.t(
      'general.price_symbol'
    )})`,
    floatingFilter: false,
    isVisible : true,
    field: 'item_value',
    // editable: true,
    cellRenderer: (params) => (
      params.data.status === "PENDING" ? <Input
        value={params.data.item_value}
        size="small"
        className="fontSize12"
        // onChange={(e) =>
        //   onChange(params.rowIndex, 'charge', e)
        // }
        onChange={(e) => {
          if(params.data.type_of_order !== "MANUAL" && parseFloat(e.target.value) < 0){
            alertMessage("Amount should not be lessthan $0.00", 'error')
          } else{
            params.setValue(e.target.value); // Update the value in the grid
            params.node.setDataValue(params.colDef.field, e.target.value); // Update the data
            onChange(params.data.id)
          }
        }}
        onBlur={e => {
            const isExceeds = parseFloat(e.target.value) > parseFloat(params.data.eligible_charge) 
            const value = isExceeds ? params.data.prev_item_value : e.target.value
            if(isExceeds){
              alertMessage(`Amount should not exceeds $` + parseFloat(params.data.eligible_charge), 'error')
              params.setValue(value); // Update the value in the grid
              params.node.setDataValue(params.colDef.field,value); // Update
            }
            if(!isEmpty(params.data.eligible_charge) &&  !isEmpty(value) && parseFloat(params.data.eligible_charge) !== 0){
              const per =  (parseFloat(value) * 100) / parseFloat(params.data.eligible_charge);
              params.node.setDataValue("settlement_percentage", ""+per.toFixed(2) );
            } else {
              params.node.setDataValue("settlement_percentage", "0");
            }
            props.handleUpdateRowData(params.data.id, 'prev_item_value', value)
        }}
        placeholder={I18n.t('reports.settlement')}
        min={0}
        precision={decimalPoints}
        style={{ width: 100 }}
      />
      : 
      checkNegitive(params.data.item_value)
    ),
    width: 140,
  }); */
  const settlementColumn = () => ({
    headerName: `${I18n.t('reports.settlement')} (${I18n.t(
      'general.price_symbol'
    )})`,
    floatingFilter: false,
    isVisible : true,
    field: 'item_value',
    editable: true,
    singleClickEdit: true,
    cellRenderer: (params) => (
      params.data.status === "PENDING" ? 
      <Input
        value={params.data.item_value}
        size="small"
        className="fontSize12"
        // onChange={(e) =>
        //   onChange(params.rowIndex, 'charge', e)
        // }
        // onChange={(e) => {
        //   if(params.data.type_of_order !== "MANUAL" && parseFloat(e.target.value) < 0){
        //     alertMessage("Amount should not be lessthan $0.00", 'error')
        //   } else{
        //     params.setValue(e.target.value); // Update the value in the grid
        //     params.node.setDataValue(params.colDef.field, e.target.value); // Update the data
        //     onChange(params.data.id)
        //   }
        // }}
        // onBlur={e => {
        //     const isExceeds = parseFloat(e.target.value) > parseFloat(params.data.eligible_charge) 
        //     const value = isExceeds ? params.data.prev_item_value : e.target.value
        //     if(isExceeds){
        //       alertMessage(`Amount should not exceeds $` + parseFloat(params.data.eligible_charge), 'error')
        //       params.setValue(value); // Update the value in the grid
        //       params.node.setDataValue(params.colDef.field,value); // Update
        //     }
        //     if(!isEmpty(params.data.eligible_charge) &&  !isEmpty(value) && parseFloat(params.data.eligible_charge) !== 0){
        //       const per =  (parseFloat(value) * 100) / parseFloat(params.data.eligible_charge);
        //       params.node.setDataValue("settlement_percentage", ""+per.toFixed(2) );
        //     } else {
        //       params.node.setDataValue("settlement_percentage", "0");
        //     }
        //     props.handleUpdateRowData(params.data.id, 'prev_item_value', value)
        // }}
        placeholder={I18n.t('reports.settlement')}
        min={0}
        precision={decimalPoints}
        style={{ width: 100 }}
      />
      : 
      checkNegitive(params.data.item_value)
    ),
    width: 140,
  });
  // const settlementColumn = () => ({
  //   headerName: `${I18n.t('reports.settlement')} (${I18n.t(
  //     'general.price_symbol'
  //   )})`,
  //   floatingFilter: false,
  //   isVisible : true,
  //   field: 'item_value',
  //   editable: true,
  //   cellEditor: "agNumberCellEditor",
  //   singleClickEdit: true,
  //   cellEditorParams: {
  //     precision: decimalPoints,
  //   },
  //   cellRenderer: (params) => 
  //   <Input 
  //     min={0}
  //     precision={decimalPoints}
  //     style={{ width: 100 }}
  //     value={params.data.item_value ? parseFloat(params.data.item_value) : ''} 
  //   />,
  // });
  
  const percentageColumn = {
    headerName: `Eligibility (%)`,
    floatingFilter: false,
    field: 'eligible_percentage',
    // editable: true,
    isVisible : true,
    cellRenderer: (params) => (
      _.get(params, "data.eligible_percentage", '') || ""
    ),
    width: 120,
  };
  const { onChange, handleUpdateRowData } = props
  const navigationAccountCode = getAccountCodeColumn(navigateToAccount);
  let listCols = props.viewType === 'SUMMARY' ? [
    renderDescription('Description', 'item_description'),
    renderDescription('Charge Description', 'item_name'),
    chargeTypeColumn,
    chargeColumn,
    percentageColumn,
    eligibleColumn,
    settlementPercentageColumn,
    settlementColumn(onChange, handleUpdateRowData),
  ]:
  [
    dvCheckboxColumn,
    orderColumn,
    hawbColumn,
    mawbColumn,
    percentageColumn,
    eligibleColumn,
    settlementPercentageColumn,
    settlementColumn(onChange, handleUpdateRowData),
    chargeTypeColumn,
    chargeColumn,
    renderDescription('Charge Description', 'item_name'),
    renderDescription('Description', 'item_description'),
    statusColumn,
    driverCol,
    warehouseColumn,
    vehicleTypeColumn,
    {
      ...navigationAccountCode,
      floatingFilter: false,
    }, 
    {...dateColumn(I18n.t('reports.service_date'), 'order_completion_date')},
    {...settlementDateColumn('Settlement Date', 'settlement_date')},
    {...InvoiceApprovalDateColumn('Invoice Approval Date','invoice_approval_date')},
    endUserColumn,
    companyColumn,
    
  ];
  if(isApprovedIncluded) {
    listCols.unshift(dvCheckboxColumn);
  }
  if(props.status=== "APPROVED"){
    listCols = listCols.filter(col => col.key !== 'checkbox')
  }
  //listCols.push({...settlementDateColumn('Settlement Date', 'settlement_date')});
  
  // if(props.settlementDateColumn){
  //   listCols.push({...settlementDateColumn('Settlement Date', 'settlement_date')});
  // }
  // if(props.showDelete){
  //   listCols.push(actionColumns);
  // }
  //listCols.push(actionColumns);
  return listCols;
};


export { getDispatchCols, getPrePlanCol, getOrderListCols, getWarehouseCols, getDriverSettlemetCols, getAccessorialsCols,getQuotesCols,getPendingOrdersCols, getDeletedOrderListCols, getChartedAccountsCols }

